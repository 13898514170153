import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /introductioninquiry
 * @CONST CONST.URL_INTRODUCTIONINQUIRY
 * @menu  상담 신청
 * @mapping 상담 신청 화면
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// css
import "/static/css/style.css";
// ex-module
import { urlState, menuListState, parentCode, checkSheetState } from "../../recoil/menuState";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { checkTypeState, curAdminTreeState, curConsultInfo, curSchduleTreeState, curMonitoringTreeState, noneUrl, mainState } from "../../recoil/assessmentState";
import AlertPop from "../common/popup/AlertPop";
import ConfirmPop from "../common/popup/ConfirmPop";
import ServiceRequestMain from "../serviceRequest/ServiceReqeustMain";
import * as CONST from "../../utils/Const";
import { langState } from "../../recoil/langState";
/**
 * @param props0 handleOpnePop : 레이어 팝업
 */
function ServiceRequestLayout() {
    // 이동
    const navigate = useNavigate();
    // recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const setCheckSheet = useSetRecoilState(checkSheetState);
    const setRecoilTreeData = useSetRecoilState(curSchduleTreeState);
    const setRecoilData = useSetRecoilState(curMonitoringTreeState);
    const setRecoilAdminData = useSetRecoilState(curAdminTreeState);
    const setMenuCodeRecoil = useSetRecoilState(parentCode);
    const [consultInfo, setConsultInfo] = useRecoilState(curConsultInfo);
    const recoilNoneUrlInfo = useRecoilValue(noneUrl);
    const [check, setCheck] = useRecoilState(checkTypeState);
    const setRecoilState = useSetRecoilState(mainState);
    const langs = useRecoilValue(langState); // recoil langState  
    //
    const [menuList, setMenuList] = useState(null);
    const [urlList, setUrlList] = useState(null);
    //공통 팝업
    const [popConfirmView, setPopConfirmView] = useState(_jsx(ConfirmPop, { popId: null, popBody: null }));
    const [popAlertView, setPopAlertView] = useState(_jsx(AlertPop, { popId: null, popBody: null }));
    // const [popInfo ,set]
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        setUrlList(urlInfo);
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
        }
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu) => {
            if (smenu.url === urlInfo)
                curMenu = smenu;
        });
        setMenuCodeRecoil((curMenu) && curMenu.parentCode);
        const tempMenu = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu) => {
            tempMenu.push({
                "menuName": smenu.menuName,
                "menuUrl": smenu.url,
            });
        });
        setMenuList(tmenuList);
        if (!recoilNoneUrlInfo) {
            // 초기화
            setCheckSheet({
                sheetTyep: "",
                spgName: ""
            });
            //초기화
            setRecoilData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilTreeData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: true },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilAdminData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "", },
                room: { roomId: "", roomName: "", },
            });
            setConsultInfo({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                reUrl: "",
                type: "",
                itemInof: []
            });
            setCheck({
                checkType: "daily",
                checkOn: false,
                nextStep: "",
                preStep: "",
                assessmentId: -1,
                checkitemId: -1,
                preCheckitemId: -1,
                checkvalueId: -1,
                itemNum: -1,
                totalComment: "",
                itemInfo: {
                    id: -1,
                    itemId: "",
                    itemName: "",
                    panelName: "",
                    serialNo: "",
                    spgId: -1
                },
                chekTypeInfo: {
                    checkTypeId: -1,
                    checkTypeName: "",
                },
                chekSubTypeInfo: {
                    checkSubTypeId: -1,
                    checkSubName: "",
                },
                chekVersionTypeInfo: {
                    checkVersionId: -1,
                    versionNo: "",
                },
                assessmentInfo: {
                    isTempSave: true,
                    reportId: -1,
                },
                checkvalue: {
                    action: "",
                    comment: "",
                    input: -1,
                    isChecked: false,
                    value: -1,
                },
                listType: -1,
                processRate: -1,
                startPage: -1,
                endpage: -1,
                dueDate: "",
                instrument: -1,
                countOpenClose: -1,
                treeName: "",
                schdeulerId: -1,
                disabled: false,
                period: -1,
                checkRef: [],
                isReportUpdated: false,
                inspector: "",
            });
            setRecoilState({
                companyId: "",
                zoneId: "",
            });
        }
    }, [urlInfo, menuRecoilList]);
    useEffect(() => {
        //공통
        setPopConfirmView(popConfirmView);
        setPopAlertView(popAlertView);
    }, [popConfirmView, popAlertView,]);
    function handleOpnePop(popId, popBody) {
        // 공통 팝업
        if (popId === "pop-confirm") {
            setPopConfirmView(_jsx(ConfirmPop, { popBody: popBody }));
        }
        else if (popId === "pop-alert") {
            setPopAlertView(_jsx(AlertPop, { popBody: popBody }));
        }
        /*    else if (popId === "pop-confirm") {
             setPopConfirmView(<ConfirmPop popBody={popBody} />);
           }
           else if (popId === "pop-alert") {
             setPopAlertView(<AlertPop popBody={popBody} />);
           }
        */
    }
    useEffect(() => {
        const handleClick = (e) => {
            const targetElement = e.target;
            const isSelect = targetElement.classList.contains("select") || targetElement.closest(".select");
            if (isSelect) {
                return;
            }
            const allSelectBoxElements = document.querySelectorAll(".select");
            allSelectBoxElements.forEach((boxElement) => {
                boxElement.classList.remove("active");
            });
        };
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap", className: `${(langs == CONST.STR_LANG_ENG) ? "lang-eng" : ""}` }, { children: [_jsx(Header, {}), (menuList) && (urlInfo) &&
                    _jsx(ServiceRequestMain, { handleOpnePop: handleOpnePop, urlInfo: urlInfo }), popConfirmView, popAlertView] })) }));
}
export default ServiceRequestLayout;
