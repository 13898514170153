import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /notfound
 * @CONST CONST.URL_NOT_FOUND
 * @menu 없음
 * @mapping 404 오류 화면
 */
import React from "react";
// css
import "/static/css/style.css";
import "/static/css/content.css";
// component
import Header from "../header/Header";
import NotFound from "../common/notfound/NotFound";
/**
 * @param props0 -
 */
function NotFoundLayout() {
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap" }, { children: [_jsx(Header, {}), _jsx(NotFound, {})] })) }));
}
export default NotFoundLayout;
