import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /monitoring, /todayinspection, /report, /operation, /consulting
 * @CONST CONST.URL_MONITORING, CONST.URL_TODAYINSPECTION, CONST.URL_CONSULTION, CONST.URL_OPERATION, CONST.URL_REPORT
 * @menu  대쉬보드
 * @mapping 모니터링 화면, 오늘의 점검 화면, 컨설팅 화면, 운영관리 화면, Report 화면
 */
import { useEffect, useState } from "react";
// recoil
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
// css
import "/static/css/style.css";
// component
import Main from "../main/Main";
// ex-module
import { useNavigate } from "react-router-dom";
import { adminTabState, adminTypeState, checkTypeState, curAdminTreeState, curMonitoringTreeState, curReportState, curSchduleTreeState, deviceState, energySolutoinHistory, noneUrl, reportTypeState, todayState, } from "../../recoil/assessmentState";
import { langState } from "../../recoil/langState";
import { checkSheetState, contactusState, menuListState, parentCode, urlState, } from "../../recoil/menuState";
import { reloadRight } from "../../recoil/userState";
import * as CUTIL from "../../utils/commUtils";
import * as CONST from "../../utils/Const";
import { useTrans } from "../../utils/langs/useTrans";
import AlertPop from "../common/popup/AlertPop";
import ConfirmPop from "../common/popup/ConfirmPop";
import EhpPostCode from "../common/postcode/EhpPostCode";
import Header from "../header/Header";
import ReportCheckMain from "../main/report/result/ReportCheckMain";
import CheckMain from "../main/todaysInspection/checklist/CheckMain";
/**
 * @param props0 handleOpnePop : 레이어 팝업
 */
function MainLayout() {
    const navigate = useNavigate();
    // recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const setCheckSheet = useSetRecoilState(checkSheetState);
    const setRecoilTreeData = useSetRecoilState(curSchduleTreeState);
    const setRecoilData = useSetRecoilState(curMonitoringTreeState);
    const setRecoilAdminData = useSetRecoilState(curAdminTreeState);
    const setMenuCodeRecoil = useSetRecoilState(parentCode);
    const [check, setCheck] = useRecoilState(checkTypeState);
    const report = useRecoilValue(reportTypeState);
    const setRecoilReportData = useSetRecoilState(curReportState);
    const setRecoilTyep = useSetRecoilState(adminTypeState);
    const setReContactusState = useSetRecoilState(contactusState);
    const setReroadRights = useSetRecoilState(reloadRight);
    const setRecoilEnergyHistory = useSetRecoilState(energySolutoinHistory);
    const setRecoilTab = useSetRecoilState(adminTabState);
    const langs = useRecoilValue(langState); // recoil langState
    const [checkReport, setCheckReport] = useRecoilState(reportTypeState);
    const recoilNoneUrlInfo = useRecoilValue(noneUrl);
    const setrecoilToday = useSetRecoilState(todayState);
    const [deviceInfo, setDeviceInfo] = useRecoilState(deviceState);
    //
    const [menuList, setMenuList] = useState(null);
    const [urlList, setUrlList] = useState(null);
    const [popListView, setPopListView] = useState(_jsx(ListViewPopup, { popBody: null }));
    const [popListView2, setPopListView2] = useState(_jsx(ListView2Popup, { popBody: null }));
    const [popListView3, setPopListView3] = useState(_jsx(ListView3Popup, { popBody: null }));
    const [popUpdateView, setPopUpdateView] = useState(_jsx(ItemUdatePop, { popId: null, popBody: null }));
    const [popTrendView, setPopTrendView] = useState(_jsx(TrendPop, { popId: null, popBody: null }));
    const [popMemoView, setPopMemoView] = useState(_jsx(MemoPop, { popId: null, popBody: null }));
    const [popMemoView2, setPopMemoView2] = useState(_jsx(MemoPop2, { popId: null, popBody: null }));
    const [popDeleteView, setPopDeleteView] = useState(_jsx(DeletePop, { popId: null, popBody: null }));
    const [popMemoDeleteView, setPopMemoDeleteView] = useState(_jsx(MemoDeletePop, { popId: null, popBody: null }));
    const [popSearchView, setPopSearchView] = useState(_jsx(SearchPop, { popId: null, popBody: null }));
    const [popShareView, setPopShareView] = useState(_jsx(SharePop, { popId: null, popBody: null }));
    const [popCheckUpdateView, setPopCheckUpdateView] = useState(_jsx(CheckUdatePop, { popId: null, popBody: null }));
    const [popCheckCloseView, setPopCheckCloseView] = useState(_jsx(CheckClosePop, { popId: null, popBody: null }));
    const [popInstrumentAddView, setPopInstrumentAddView] = useState(_jsx(CheckInstrumentAddPop, { popId: null, popBody: null }));
    const [popInstrumentAddView2, setPopInstrumentAddView2] = useState(_jsx(CheckInstrumentAddPop2, { popId: null, popBody: null }));
    const [popInstrumentEditView, setPopInstrumentEditView] = useState(_jsx(CheckInstrumentEditPop, { popId: null, popBody: null }));
    const [popCheckOpenCloseView, setPopCheckOpenCloseView] = useState(_jsx(CheckOpenClosePop, { popId: null, popBody: null }));
    const [popImageView, setPopImageView] = useState(_jsx(ImagePop, { popId: null, popBody: null }));
    const [popImageEditView, setPopImageEditView] = useState(_jsx(ImageEditPop, { popId: null, popBody: null }));
    const [popImageDeleteView, setPopImageDeleteView] = useState(_jsx(ImageDeletePop, { popId: null, popBody: null }));
    const [popImageTotalEditView, setPopImageTotalEditView] = useState(_jsx(ImageTotalEditPop, { popId: null, popBody: null }));
    const [popReportiew, setPopReportView] = useState(_jsx(ReportPop, { popId: null, popBody: null }));
    const [popTitleEditView, setPopTitleEditView] = useState(_jsx(TitleEditPop, { popId: null, popBody: null }));
    const [popEnergyGideView, setPopEnergyGideView] = useState(_jsx(EnergyGidePop, { popId: null, popBody: null }));
    const [popEnergyGideView2, setPopEnergyGideView2] = useState(_jsx(EnergyGidePop2, { popId: null, popBody: null }));
    const [popEnergyGideView3, setPopEnergyGideView3] = useState(_jsx(EnergyGidePop3, { popId: null, popBody: null }));
    const [popEnergyGideView4, setPopEnergyGideView4] = useState(_jsx(EnergyGidePop4, { popId: null, popBody: null }));
    const [popEnergyGideView5, setPopEnergyGideView5] = useState(_jsx(EnergyGidePop5, { popId: null, popBody: null }));
    const [popCompanyAddView, setPopCompanyAddView] = useState(_jsx(CompanyAddPop, { popId: null, popBody: null }));
    const [popCancel, setPopCancel] = useState(_jsx(CancelPop, { popId: null }));
    const [popCancel2, setPopCancel2] = useState(_jsx(CancelPop2, { popId: null }));
    const [popSiteAddView, setPopSiteAddView] = useState(_jsx(SiteAddPop, { popId: null, popBody: null }));
    const [popSiteAddView2, setPopSiteAddView2] = useState(_jsx(SiteAddPop2, { popId: null, popBody: null }));
    const [popSiteEditView, setPopSiteEditView] = useState(_jsx(SiteEditPop, { popId: null, popBody: null }));
    const [popDeviceAllView, setPopDeviceAllView] = useState(_jsx(DeviceAllPop, { popId: null, popBody: null }));
    const [popReportMemo1View, setPopReportMemo1View] = useState(_jsx(ReportMemoPop1, { popId: null, popBody: null }));
    const [popReportMemo2View, setPopReportMemo2View] = useState(_jsx(ReportMemoPop2, { popId: null, popBody: null }));
    const [popInspectorView, setPopInspectorView] = useState(_jsx(InspectorPop, { popId: null, popBody: null }));
    // SEI2.0 ESS 분석
    const [pop01View, setPop01View] = useState(_jsx(Pop01, { popId: null, popBody: null }));
    const [pop02View, setPop02View] = useState(_jsx(Pop02, { popId: null, popBody: null }));
    const [pop03View, setPop03View] = useState(_jsx(Pop03, { popId: null, popBody: null }));
    const [pop04View, setPop04View] = useState(_jsx(Pop04, { popId: null, popBody: null }));
    const [pop05View, setPop05View] = useState(_jsx(Pop05, { popId: null, popBody: null }));
    const [pop06View, setPop06View] = useState(_jsx(Pop06, { popId: null, popBody: null }));
    //공통 팝업
    const [popConfirmView, setPopConfirmView] = useState(_jsx(ConfirmPop, { popId: null, popBody: null }));
    const [popAlertView, setPopAlertView] = useState(_jsx(AlertPop, { popId: null, popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(_jsx(EhpPostCode, { popBody: null }));
    // const [popInfo ,set]
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        setUrlList(urlInfo);
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
        }
        let curMenu = null;
        tmenuList
            .filter((smenu) => smenu.url === urlInfo)
            .map((smenu) => {
            if (smenu.url === urlInfo)
                curMenu = smenu;
        });
        setMenuCodeRecoil(curMenu && curMenu.parentCode);
        const tempMenu = [];
        tmenuList
            .filter((smenu) => curMenu && smenu.parentCode === curMenu.parentCode)
            .map((smenu) => {
            tempMenu.push({
                menuName: smenu.menuName,
                menuUrl: smenu.url,
            });
        });
        setMenuList(tmenuList);
        if (!recoilNoneUrlInfo) {
            // 초기화
            setCheckSheet({
                sheetTyep: "",
                spgName: "",
            });
            //초기화
            setRecoilData({
                //모니터링
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false },
            });
            setRecoilTreeData({
                //오늘의 점검
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: true },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false },
            });
            setRecoilAdminData({
                // 운영관리
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "" },
                room: { roomId: "", roomName: "" },
            });
            setCheck({
                //진단 점검
                checkType: "daily",
                checkOn: false,
                nextStep: "",
                preStep: "",
                assessmentId: -1,
                checkitemId: -1,
                preCheckitemId: -1,
                checkvalueId: -1,
                itemNum: -1,
                totalComment: "",
                itemInfo: {
                    id: -1,
                    itemId: "",
                    itemName: "",
                    panelName: "",
                    serialNo: "",
                    spgId: -1,
                },
                chekTypeInfo: {
                    checkTypeId: -1,
                    checkTypeName: "",
                },
                chekSubTypeInfo: {
                    checkSubTypeId: -1,
                    checkSubName: "",
                },
                chekVersionTypeInfo: {
                    checkVersionId: -1,
                    versionNo: "",
                },
                assessmentInfo: {
                    isTempSave: true,
                    reportId: -1,
                },
                checkvalue: {
                    action: "",
                    comment: "",
                    input: -1,
                    isChecked: false,
                    value: -1,
                },
                listType: -1,
                processRate: -1,
                startPage: -1,
                endpage: -1,
                dueDate: "",
                instrument: -1,
                countOpenClose: -1,
                treeName: "",
                schdeulerId: -1,
                disabled: false,
                period: -1,
                checkRef: [],
                isReportUpdated: false,
                inspector: "",
            });
            setRecoilReportData({
                // 단일 레포트
                companyId: "",
                subZoneId: "",
                name: "",
                roomId: "",
                roomName: "",
                spgId: -1,
                spgName: "",
            });
            setrecoilToday(null);
            setDeviceInfo({
                id: 1,
                name: "등록된 설비",
            });
            setRecoilTyep("zone");
            setReContactusState(null);
            window.scrollTo({ top: 0 });
            setRecoilEnergyHistory(null);
            setRecoilTab("SITE");
            setCheckReport(Object.assign(Object.assign({}, checkReport), { smenu: 'report' }));
        }
    }, [urlInfo, menuRecoilList]);
    useEffect(() => {
        setPopListView(popListView);
        setPopListView2(popListView2);
        setPopListView3(popListView3);
        setPopMemoView(popMemoView);
        setPopDeleteView(popDeleteView);
        setPopMemoDeleteView(popMemoDeleteView);
        setPopSearchView(popSearchView);
        setPopUpdateView(popUpdateView);
        setPopCheckUpdateView(popCheckUpdateView);
        setPopCheckCloseView(popCheckCloseView);
        setPopInstrumentAddView(popInstrumentAddView);
        setPopInstrumentEditView(popInstrumentEditView);
        setPopCheckOpenCloseView(popCheckOpenCloseView);
        setPopImageEditView(popImageEditView);
        setPopImageDeleteView(popImageDeleteView);
        setPopImageView(popImageView);
        setPopImageTotalEditView(popImageTotalEditView);
        setPopShareView(popShareView);
        setPopReportView(popReportiew);
        setPopTitleEditView(popTitleEditView);
        setPopEnergyGideView(popEnergyGideView);
        setPopEnergyGideView2(popEnergyGideView2);
        setPopEnergyGideView3(popEnergyGideView3);
        setPopEnergyGideView4(popEnergyGideView4);
        setPopEnergyGideView5(popEnergyGideView5);
        setPopTrendView(popTrendView);
        setPopInstrumentAddView2(popInstrumentAddView2);
        setPopCancel(popCancel);
        setPopCompanyAddView(popCompanyAddView);
        setPopSiteAddView(popSiteAddView);
        setPopMemoView2(popMemoView2);
        setPopSiteEditView(popSiteEditView);
        setPopSiteAddView2(popSiteAddView2);
        setPopCancel2(popCancel2);
        setPopDeviceAllView(popDeviceAllView);
        setPopReportMemo1View(popReportMemo1View);
        setPopReportMemo2View(popReportMemo2View);
        setPopInspectorView(popInspectorView);
        // SEI 2.0 ESS 분석
        setPop01View(pop01View);
        setPop02View(pop02View);
        setPop03View(pop03View);
        setPop04View(pop04View);
        setPop05View(pop05View);
        setPop06View(pop06View);
        // 공통
        setPopConfirmView(popConfirmView);
        setPopAlertView(popAlertView);
        setPopPostCode(popPostCode);
    }, [
        popListView,
        popListView2,
        popListView3,
        popUpdateView,
        popMemoView,
        popSearchView,
        popCheckUpdateView,
        popCheckCloseView,
        popDeleteView,
        popInstrumentAddView,
        popInstrumentEditView,
        popCheckOpenCloseView,
        popImageEditView,
        popImageDeleteView,
        popImageView,
        popImageTotalEditView,
        popShareView,
        popReportiew,
        popTitleEditView,
        popEnergyGideView,
        popEnergyGideView2,
        popEnergyGideView3,
        popEnergyGideView4,
        popEnergyGideView5,
        popTrendView,
        popInstrumentAddView2,
        popCancel,
        popCompanyAddView,
        popSiteAddView,
        popMemoView2,
        popSiteEditView,
        popSiteAddView2,
        popCancel2,
        popDeviceAllView,
        popReportMemo1View,
        popReportMemo2View,
        popInspectorView,
        // SEI 2.0 ESS 분석
        pop01View,
        pop02View,
        pop03View,
        pop04View,
        pop05View,
        pop06View,
        //
        popConfirmView,
        popAlertView,
        popPostCode,
    ]);
    function handleOpnePop(popId, popBody) {
        if (popId === "pop-s-add") {
            setPopListView(_jsx(ListViewPopup, { popBody: popBody }));
        }
        if (popId === "pop-ss-add") {
            setPopListView2(_jsx(ListView2Popup, { popBody: popBody }));
        }
        if (popId === "pop-sss-add") {
            setPopListView3(_jsx(ListView3Popup, { popBody: popBody }));
        }
        else if (popId === "pop-s-edit") {
            setPopUpdateView(_jsx(ItemUdatePop, { popBody: popBody }));
        }
        else if (popId === "pop-trend") {
            setPopTrendView(_jsx(TrendPop, { popBody: popBody }));
        }
        else if (popId === "pop-memo") {
            setPopMemoView(_jsx(MemoPop, { popBody: popBody }));
        }
        else if (popId === "pop-memo2") {
            setPopMemoView2(_jsx(MemoPop2, { popBody: popBody }));
        }
        else if (popId === "pop-delete") {
            setPopDeleteView(_jsx(DeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-delete-memo") {
            setPopMemoDeleteView(_jsx(MemoDeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-search-small") {
            setPopSearchView(_jsx(SearchPop, { popBody: popBody }));
        }
        else if (popId === "pop-check-edit") {
            setPopCheckUpdateView(_jsx(CheckUdatePop, { popBody: popBody }));
        }
        else if (popId === "pop-check-close") {
            setPopCheckCloseView(_jsx(CheckClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-add-info2") {
            setPopInstrumentAddView2(_jsx(CheckInstrumentAddPop2, { popBody: popBody }));
        }
        else if (popId === "pop-edit-info") {
            setPopInstrumentEditView(_jsx(CheckInstrumentEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-num") {
            setPopCheckOpenCloseView(_jsx(CheckOpenClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-add-ins") {
            setPopInspectorView(_jsx(InspectorPop, { popBody: popBody }));
        }
        else if (popId === "pop-img") {
            setPopImageView(_jsx(ImagePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-image") {
            setPopImageEditView(_jsx(ImageEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-delete-image") {
            setPopImageDeleteView(_jsx(ImageDeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-img") {
            setPopImageTotalEditView(_jsx(ImageTotalEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-share") {
            setPopShareView(_jsx(SharePop, { popBody: popBody }));
        }
        else if (popId === "pop-report") {
            setPopReportView(_jsx(ReportPop, { popBody: popBody }));
        }
        else if (popId === "pop-tit-edit") {
            setPopTitleEditView(_jsx(TitleEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-energy-guide") {
            setPopEnergyGideView(_jsx(EnergyGidePop, { popBody: popBody }));
        }
        else if (popId === "pop-guide2") {
            setPopEnergyGideView2(_jsx(EnergyGidePop2, { popBody: popBody }));
        }
        else if (popId === "pop-add-info") {
            setPopInstrumentAddView(_jsx(CheckInstrumentAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-guide3") {
            setPopEnergyGideView3(_jsx(EnergyGidePop3, { popBody: popBody }));
        }
        else if (popId === "pop-guide4") {
            setPopEnergyGideView4(_jsx(EnergyGidePop4, { popBody: popBody }));
        }
        else if (popId === "pop-guide5") {
            setPopEnergyGideView5(_jsx(EnergyGidePop5, { popBody: popBody }));
        }
        else if (popId === "pop-cancel") {
            setPopCancel(_jsx(CancelPop, { popBody: popBody }));
        }
        else if (popId === "pop-cancel2") {
            setPopCancel2(_jsx(CancelPop2, { popBody: popBody }));
        }
        else if (popId === "pop-add-company") {
            setPopCompanyAddView(_jsx(CompanyAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-detail") {
            setPopSiteAddView(_jsx(SiteAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-detail2") {
            setPopSiteAddView2(_jsx(SiteAddPop2, { popBody: popBody }));
        }
        else if (popId === "pop-add-detail-edit") {
            setPopSiteEditView(_jsx(SiteEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-finish-ok") {
            setPopDeviceAllView(_jsx(DeviceAllPop, { popBody: popBody }));
        }
        else if (popId === "pop-memo-r1") {
            setPopReportMemo1View(_jsx(ReportMemoPop1, { popBody: popBody }));
        }
        else if (popId === "pop-memo-r2") {
            setPopReportMemo2View(_jsx(ReportMemoPop2, { popBody: popBody }));
        }
        // SIE 2.0 ESS분석
        else if (popId === "pop-01") {
            setPop01View(_jsx(Pop01, { popBody: popBody }));
        }
        else if (popId === "pop-02") {
            setPop02View(_jsx(Pop02, { popBody: popBody }));
        }
        else if (popId === "pop-03") {
            setPop03View(_jsx(Pop03, { popBody: popBody }));
        }
        else if (popId === "pop-04") {
            setPop04View(_jsx(Pop04, { popBody: popBody }));
        }
        else if (popId === "pop-05") {
            setPop05View(_jsx(Pop05, { popBody: popBody }));
        }
        else if (popId === "pop-06") {
            setPop06View(_jsx(Pop06, { popBody: popBody }));
        }
        // 공통 팝업
        else if (popId === "pop-confirm") {
            setPopConfirmView(_jsx(ConfirmPop, { popBody: popBody }));
        }
        else if (popId === "pop-alert") {
            setPopAlertView(_jsx(AlertPop, { popBody: popBody }));
        }
        else if (popId === "pop-postcode") {
            setPopPostCode(_jsx(EhpPostCode, { popBody: popBody }));
        }
    }
    useEffect(() => {
        const handleClick = (e) => {
            const targetElement = e.target;
            const isSelect = targetElement.classList.contains("select") ||
                targetElement.closest(".select");
            if (isSelect) {
                return;
            }
            const allSelectBoxElements = document.querySelectorAll(".select");
            allSelectBoxElements.forEach((boxElement) => {
                boxElement.classList.remove("active");
            });
        };
        window.addEventListener("click", handleClick);
        return () => window.removeEventListener("click", handleClick);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap", className: `${check.checkOn || report.checkType == "view" ? "float-page" : ""} ${check.checkOn &&
                check.nextStep !== "checkList" &&
                check.nextStep !== "result"
                ? "onepage"
                : ""} 
          ${(langs == CONST.STR_LANG_ENG) ? "lang-eng" : ""}
          ` }, { children: [!check.checkOn && _jsx(Header, {}), menuList &&
                    urlInfo &&
                    !check.checkOn &&
                    report.checkType == "main" && (_jsx(Main, { handleOpnePop: handleOpnePop, urlInfo: urlInfo })), check.checkOn && (_jsx(CheckMain, { handleOpnePop: handleOpnePop, check: check })), report.checkType == "view" && (_jsx(ReportCheckMain, { handleOpnePop: handleOpnePop })), popListView, popListView2, popListView3, popUpdateView, popMemoView, popMemoView2, popDeleteView, popMemoDeleteView, popSearchView, popCheckUpdateView, popCheckCloseView, popInstrumentAddView, popInstrumentAddView2, popInstrumentEditView, popCheckOpenCloseView, popImageView, popImageEditView, popImageDeleteView, popImageTotalEditView, popShareView, popReportiew, popTitleEditView, popEnergyGideView, popEnergyGideView2, popEnergyGideView3, popEnergyGideView4, popEnergyGideView5, popTrendView, popCancel, popCancel2, popCompanyAddView, popSiteAddView, popSiteAddView2, popSiteEditView, popDeviceAllView, popReportMemo1View, popReportMemo2View, popInspectorView, pop01View, pop02View, pop03View, pop04View, pop05View, pop06View, popConfirmView, popAlertView, popPostCode] })) }));
}
export default MainLayout;
function ListViewPopup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-s-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ListView2Popup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-ss-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ListView3Popup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-sss-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function MemoPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function MemoPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function DeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function MemoDeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete-memo", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete-memo") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ItemUdatePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-s-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function TrendPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-trend", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function SearchPop(props) {
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-search-small", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uAC80\uC0C9" })), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-search-small") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function SharePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-share", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uACF5\uC720" })), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckUdatePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-check-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckClosePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-check-close", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.나가기") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-check-close") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckInstrumentAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckInstrumentAddPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-info2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckInstrumentEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function InspectorPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-ins", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CheckOpenClosePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-num", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ImageEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-image", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ImageDeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete-image", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete-image") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ImagePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-img", className: "popup-layer js-layer hidden popup-type1 wide" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [htmlClose && htmlClose, _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ImageTotalEditPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-img", className: "popup-layer js-layer hidden popup-type1 w630" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.현장사진추가") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-edit-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ReportPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-report", className: "popup-layer js-layer hidden popup-type3" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: t("라벨.종합레포트목록") })), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-report") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function TitleEditPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-tit-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), htmlClose && htmlClose] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function EnergyGidePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-energy-guide", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("팝업.계약전력변경.헤더") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-energy-guide") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function EnergyGidePop2(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-guide2", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("팝업.요금제변경.헤더") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-guide2") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function EnergyGidePop3(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-guide3", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("팝업.역률별요금.헤더") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-guide3") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function EnergyGidePop4(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-guide4", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("팝업.피크최적화.헤더") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-guide4") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function EnergyGidePop5(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-guide5", className: "popup-layer js-layer hidden popup-type1 w524" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("팝업.상관관계분석.헤더") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-guide5") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CancelPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-cancel", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.반려") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-cancel") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CancelPop2(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-cancel2", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.반려") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-cancel") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function CompanyAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-company", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function SiteAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-detail", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function SiteAddPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-detail2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function SiteEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-detail-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function DeviceAllPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-finish-ok", className: "popup-layer js-layer hidden popup-type1 w1496 h97vh" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ReportMemoPop1(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo-r1", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
function ReportMemoPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo-r2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
// 에너지 솔루션 ESS 분선 팝압
function Pop01(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-01", 
            // className="popup-layer js-layer hidden popup-type1 w880">
            className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function Pop02(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-02", 
            // className="popup-layer js-layer hidden popup-type1 w560">
            className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function Pop03(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-03", 
            // className="popup-layer js-layer hidden popup-type1 w880">
            className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: htmlClose && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function Pop03_1(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-03-1", className: "popup-layer js-layer hidden popup-basic inner-popup" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uC800\uC7A5" })), _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), _jsx("div", Object.assign({ className: "popup__body" }, { children: _jsx("p", { children: "\uC124\uBE44 \uC815\uBCF4\uAC00 \uC800\uC7A5\uB418\uC5C8\uC2B5\uB2C8\uB2E4." }) })), _jsxs("div", Object.assign({ className: "popup__footer" }, { children: [_jsx("button", Object.assign({ type: "button", className: "bg-gray js-close" }, { children: _jsx("span", { children: "\uCDE8\uC18C" }) })), _jsx("button", Object.assign({ type: "button" }, { children: _jsx("span", { children: "\uD655\uC778" }) }))] }))] })) }));
}
function Pop04(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-04", className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function Pop05(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-05", 
            // className="popup-layer js-layer hidden popup-type2 w1496 h82vh">
            className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function Pop06(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    // className 수정
    const htmlClass = !CUTIL.isnull(popBody)
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClass")
                ? popBody.props.htmlClass
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-06", 
            // className="popup-layer js-layer hidden popup-type1 w560">
            className: `popup-layer js-layer hidden ${(!htmlClass) ? "" : htmlClass}` }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), CUTIL.isnull(popBody) ? _jsx(_Fragment, {}) : popBody] })) }));
}
;
function jscloses_Popup(id) {
    CUTIL.jsclose_Popup(id);
}
