import { atom, selector } from "recoil";
import * as CONST from "../utils/Const";
// TodoInput에서 입력하는 값을 atom으로 관리하는 방식
export const langState = atom({
    key: 'langState',
    // key의 값은 항상 고유값이어야 합니다.
    default: CONST.STR_LANG_KOR,
});
export const langId = atom({
    key: 'langId',
    // key의 값은 항상 고유값이어야 합니다.
    default: 1,
});
export const getApiLangState = selector({
    key: 'getApiLangState',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const langs = get(langState);
        return ((langs === CONST.STR_LANG_KOR)
            ? CONST.STR_APILANG_KOR
            : (langs === CONST.STR_LANG_ENG)
                ? CONST.STR_APILANG_ENG
                : (langs === CONST.STR_LANG_CHA)
                    ? CONST.STR_APILANG_CHA
                    : CONST.STR_APILANG_KOR);
    },
});
export const getApiLangId = selector({
    key: 'getApiLangId',
    // key의 값은 항상 고유값이어야 합니다.
    get: ({ get }) => {
        const langs = get(langState);
        return ((langs === CONST.STR_LANG_KOR)
            ? 1
            : (langs === CONST.STR_LANG_ENG)
                ? 2
                : (langs === CONST.STR_LANG_CHA)
                    ? 3
                    : 1);
    },
});
