import { atom } from "recoil";
/* SEI 01 */
// 햄버거 메뉴 클릭 여부 관리 Recoil
export const menuState = atom({
    key: 'menuState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerSettingState = atom({
    key: 'headerSettingState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerAlarmState = atom({
    key: 'headerAlarmState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const headerUserInfoState = atom({
    key: 'headerUserInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const treebarState = atom({
    key: 'treebarState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const treeMenuState = atom({
    key: 'treeMenuState',
    // key의 값은 항상 고유값이어야 합니다.
    default: true,
});
// 이동할 URL 정보 관리 Recoil
export const urlState = atom({
    key: 'urlState',
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
// 이동할 parentCode 정보 관리 Recoil
export const parentCode = atom({
    key: 'parentCode',
    // key의 값은 항상 고유값이어야 합니다.
    default: "",
});
// 박스별  Loading 여부 정보 관리 Recoil
export const loadingState = atom({
    key: 'loadingState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
// 박스별  Loading 여부 정보 관리 2 Recoil
export const loadingState2 = atom({
    key: 'loadingState2',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
// 전체 Loading 여부 정보 관리 Recoil
export const loadingBoxState = atom({
    key: 'loadingBoxState',
    // key의 값은 항상 고유값이어야 합니다.
    default: false,
});
export const menuInfoState = atom({
    key: 'menuInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        menuCode: "",
        menuName: "",
        parentCode: "",
        description: "",
        isExternalUrl: false,
        url: "",
    }
});
export const menuListState = atom({
    key: 'menuListState',
    // key의 값은 항상 고유값이어야 합니다.
    default: [],
});
export const czoneInfoState = atom({
    key: 'czoneInfoState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        company: { companyId: "", companyName: "", },
        zone: { zoneId: "", zoneName: "", },
    }
});
// 체크시트 관리 
export const checkSheetState = atom({
    key: 'checkSheetState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        sheetTyep: "",
        spgName: ""
    }
});
// spg 관리 
export const spgState = atom({
    key: 'spgState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        spgId: -1,
        compSpgId: -1,
        companySpgId: -1,
        spgName: ""
    }
});
// spg 관리 
export const compState = atom({
    key: 'compState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        companyId: "",
        companyName: "",
        id: -1
    }
});
export const checkSheetInfo = atom({
    key: 'checkSheetInfo',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        checkversionId: -1,
        checkSubTypeId: -1,
        count: -1,
        checkSubTypeName: "",
        versionNo: "",
        enabled: false,
        companyId: -1,
        description: ""
    }
});
export const checkListInfo = atom({
    key: 'checkListInfo',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        checkGroup: { groupId: -1, groupName: "" },
        checkItemId: -1,
        description: "",
        instrument: "",
        name: "",
        type: -1,
        weight: -1,
        checkItemRef: [],
        itemAge: ""
    }
});
// 사업장 관리 
export const siteState = atom({
    key: 'siteState',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        siteTyep: "company",
        companyId: "",
        companyName: ""
    }
});
// 통합검색 상세페이지 class 추가 
export const detail = atom({
    key: 'detail',
    // key의 값은 항상 고유값이어야 합니다.
    default: false
});
// 통합 검색 상세 정보
export const detailList = atom({
    key: 'detailList',
    // key의 값은 항상 고유값이어야 합니다.
    default: null
});
// 통합검색 상세페이지 -> 마켓플레이스 시 전역저장 
export const detailHistory = atom({
    key: 'detailHistory',
    // key의 값은 항상 고유값이어야 합니다.
    default: {
        page: 0,
        bigGroup: "energy",
        searchKind: 0,
        searchLike: "",
        tagSearch: [],
        searchFilter: "",
        zone: false,
        zoneId: null,
    }
});
export const contactusState = atom({
    key: 'contactusState',
    // key의 값은 항상 고유값이어야 합니다.
    default: null
});
// 통합 검색 상세 정보에서 태그 저장
export const tagName = atom({
    key: 'tagName',
    // key의 값은 항상 고유값이어야 합니다.
    default: null
});
// 통합 검색 화면 
export const marketWork = atom({
    key: 'marketWork',
    // key의 값은 항상 고유값이어야 합니다.
    default: "LIST"
});
// 커뮤니티 관리 메뉴
export const cmmType = atom({
    key: "cmmType",
    default: {
        tabId: 0,
        nickName: "",
    }
});
/* SEI 01 */ 
