export const defWidth = 1920;
//LIST COUNT
export const NUM_WLISTCNT = 10;
export const NUM_MLISTCNT = 3;
//AUTO 로그인
export const NUM_DIFFTIME_SEC_DEV = 600;
export const NUM_DIFFTIME_SEC_PROD = 600;
export const NUM_DIFFTIME_SEC = process.env.NODE_ENV.includes('dev')
    ? NUM_DIFFTIME_SEC_DEV
    : NUM_DIFFTIME_SEC_PROD;
//LANGUAGES
export const STR_LANG_KOR = "ko_KR";
export const STR_LANG_ENG = "en_US";
export const STR_LANG_CHA = "cn_CH";
export const STR_LANG_JPA = "jp_JA";
export const STR_LANG_I18N = "langs";
//
export const STR_APILANG_KOR = "Korean";
export const STR_APILANG_ENG = "English";
export const STR_APILANG_CHA = "Chinese";
export const STR_APILANG_JPA = "jp_JA";
//Korean, English, Chinese
//ROLE
export const USERROLE_ADMIN = "ROLE_ADMIN";
export const USERROLE_ENGINEER = "ROLE_ENGINEER";
export const USERROLE_SUPER = "ROLE_SUPERUSER";
export const USERROLE_USER = "ROLE_USER";
export const USERROLE_NONE = "ROLE_NONE";
//로그인 STRING KEY
export const STR_USERID = "userId";
export const STR_USERROLE = "role";
export const STR_TOKEN = "token";
export const STR_TOKEN_EXPIRETIME = "tokenExpireTime";
export const STR_REFRESHTOKEN = "refreshToken";
export const STR_REFRESHTOKEN_EXPIRETIME = "refreshTokenExpireTime";
export const STR_KEEP_LOGIN_YN = "keepLoginYn";
export const STR_EHP_MENULIST = "ehpMenuList";
export const STR_EHP_DASHBODRD_CARDORDER = "ehpDashboardCardorder";
export const STR_EHP_DASHBODRD_PIN = "ehpDashboardPin";
// URL PARAM
export const STR_PARAM_DATA = "EHP_EX_DATA";
export const STR_PARAM_PDFID = "EHP_PDF_ID";
export const STR_PARAM_PDFNM = "EHP_PDF_NM";
export const STR_EMAIL = "email";
export const STR_KEY = "key";
// 메뉴 URL
// SEI 랜딩
export const URL_SEI = "/";
export const URL_MAIN = "/main";
export const URL_WHATWEDO = "/whatewdo";
export const URL_MARKETPLACE = "/marketplace";
export const URL_CONTACTUS = "/contactus";
export const URL_COMMUNITY = "/community";
export const URL_MYPAGE = "/mypage";
export const URL_LOGININTRO = "/loginintro";
// SEI 대쉬보드 로그인
export const URL_LOGIN = "/login";
// SEI 대쉬보드 메인
export const URL_MONITORING = "/monitoring";
export const URL_TODAYINSPECTION = "/todayinspection";
export const URL_ENERGYSOLUTION = "/energySolution";
export const URL_CONSULTION = "/consulting";
export const URL_OPERATION = "/operation";
export const URL_REPORT = "/report";
// SEI Management
export const URL_APPROVALMANAGEMENT = "/approvalmanagement";
export const URL_USERMANAGEMENT = "/usermanagement";
export const URL_SITEMANAGEMENT = "/sitemanagement";
export const URL_MESSAGEMANAGEMENT = "/messagemanagement";
export const URL_MENUMANAGEMENT = "/menumanagement";
export const URL_CHECKSHEETMANAGEMENT = "/checksheetmanagement";
export const URL_ECONSULTMANAGEMENT = "/econsultmanagement";
export const URL_SPGMANAGEMENT = "/spgmanagement";
export const URL_SOLUTIONMANAMENT = "/solutionmanagement";
export const URL_COMMUNITYMANAGEMENT = "/communitymanagement";
export const URL_INFOMANAGEMENT = "/infomanagement";
// SEU 서비스 요청
export const URL_INTRODUCTIONINQUIRY = "/introductioninquiry";
export const URL_HELP = "/help";
export const URL_NOT_FOUND = "/notfound";
export const URL_SYSTEM_ERROR = "/systemerror";
//
export const URL_COMM_PDFVIEWER = "/pdfviewer";
export const URL_VERIFYEMAIL = "/verifyEmail";
export const URL_VERIFYEMAILCOMMUNITY = "/verifyEmailCommunity";
//
export const URL_CALENDAR = "/calendar";
//
export const startUrl = "startUrl";
//API RESULT
export const API_200 = 200; // HTTP 200
export const API_999 = 999; // unknown system error
export const APISTATUS_500 = 500; // unknown system error
export const MSG_SUCCESS = "success"; // api msg
export const MSG_FAIL = "fail"; // api msg
//APP FIELD DATA
export const FLD_AUTOFIELD = "autofield";
export const FLD_MSG_DELETE_COMMON = "요청하신 정보가 삭제되었습니다.";
// pop 관련
export const POP_CONFIRM = "pop-confirm";
export const POP_CMM_CONFIRM = "pop-cmmConfirm";
export const POP_ALERT = "pop-alert";
export const POP_RELOAD = "pop-reload";
export const POP_LANDING = "pop1";
export const POP_LOGIN = "pop-login";
export const POP_AUTO = "pop-auto-nickname";
// pop type
export const TYEP_NONE = "none";
export const TYEP_INNRE = "inner";
export const TYEP_ERROR = "error";
export const TYEP_LANDING = "landing";
