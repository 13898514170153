import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { detail } from "../../../recoil/menuState";
import CommunityWrite from "./CommunityWrite";
import { useRecoilState } from "recoil";
export default function CommunityWriteLink(props) {
    let handleOpnePop = props.handleOpnePop;
    const [details, setDetils] = useRecoilState(detail);
    const [viewType, setViewType] = useState("");
    setDetils(true);
    return (_jsx(_Fragment, { children: _jsx("main", Object.assign({ className: `container` }, { children: _jsx(CommunityWrite, { handleOpnePop: handleOpnePop, setViewType: setViewType }) })) }));
}
