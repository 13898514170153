import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /approvalmanagement, /usermanagement, /sitemanagement, /messagemanagement, /menumanagement, /checksheetmanagement, /econsultmanagement
 * @CONST CONST.URL_APPROVALMANAGEMENT, CONST.URL_USERMANAGEMENT, CONST.URL_SITEMANAGEMENT, CONST.URL_MESSAGEMANAGEMENT, CONST.URL_MENUMANAGEMENT,CONST.URL_CHECKSHEETMANAGEMENT, CONST.URL_ECONSULTMANAGEMENT
 * @menu Managemnet
 * @mapping 승인 관리 화면, 사용자 관리 화면, 사업장 관리 화면, 메시지 관리 화면, 메뉴 관리 화면, Check Sheet 관리 화면, 이메일 상담 관리 화면
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// css
import "/static/css/style.css";
// component
// ex-module
import { urlState, menuListState, parentCode, contactusState } from "../../recoil/menuState";
import Header from "../header/Header";
import * as CUTIL from "../../utils/commUtils";
import AlertPop from "../common/popup/AlertPop";
import ConfirmPop from "../common/popup/ConfirmPop";
import ManagementMain from "../management/ManagementMain";
import { checkTypeState, curAdminTreeState, curSchduleTreeState, curMonitoringTreeState, noneUrl, mainState } from "../../recoil/assessmentState";
import EhpPostCode from "../common/postcode/EhpPostCode";
import { useTrans } from "../../utils/langs/useTrans";
import { langState } from "../../recoil/langState";
import * as CONST from "../../utils/Const";
/**
 * @param props0 handleOpnePop : 레이어 팝업
 */
function ManagementLayout() {
    // recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const setRecoilTreeData = useSetRecoilState(curSchduleTreeState);
    const setRecoilData = useSetRecoilState(curMonitoringTreeState);
    const setRecoilAdminData = useSetRecoilState(curAdminTreeState);
    const setCheck = useSetRecoilState(checkTypeState);
    const setMenuCodeRecoil = useSetRecoilState(parentCode);
    const setRecoilState = useSetRecoilState(mainState);
    const setReContactusState = useSetRecoilState(contactusState);
    const recoilNoneUrlInfo = useRecoilValue(noneUrl);
    const langs = useRecoilValue(langState); // recoil langState  
    const [menuList, setMenuList] = useState(null);
    const [popListView, setPopListView] = useState(_jsx(ListViewPopup, { popBody: null }));
    const [popListView2, setPopListView2] = useState(_jsx(ListView2Popup, { popBody: null }));
    const [popGuideView, setPopGuideView] = useState(_jsx(GuidePop, { popId: null, popBody: null }));
    const [popMomeView, setPopMemoView] = useState(_jsx(MemoPop, { popId: null, popBody: null }));
    const [popDeleteView, setPopDeleteView] = useState(_jsx(DeletePop, { popId: null, popBody: null }));
    const [popDeleteView2, setPopDeleteView2] = useState(_jsx(DeletePop2, { popId: null, popBody: null }));
    const [popSearchView, setPopSearchView] = useState(_jsx(SearchPop, { popId: null, popBody: null }));
    const [popCheckSheetView, setPopCheckSheetView] = useState(_jsx(CheckSheetPop, { popId: null, popBody: null }));
    const [popCheckCloseView, setPopCheckCloseView] = useState(_jsx(CheckClosePop, { popId: null, popBody: null }));
    const [popInstrumentAddView, setPopInstrumentAddView] = useState(_jsx(CheckInstrumentAddPop, { popId: null, popBody: null }));
    const [popInstrumentEditView, setPopInstrumentEditView] = useState(_jsx(CheckInstrumentEditPop, { popId: null, popBody: null }));
    const [popCheckOpenCloseView, setPopCheckOpenCloseView] = useState(_jsx(CheckOpenClosePop, { popId: null, popBody: null }));
    const [popImageView, setPopImageView] = useState(_jsx(ImagePop, { popId: null, popBody: null }));
    const [popImageEditView, setPopImageEditView] = useState(_jsx(ImageEditPop, { popId: null, popBody: null }));
    const [popImageDeleteView, setPopImageDeleteView] = useState(_jsx(ImageDeletePop, { popId: null, popBody: null }));
    const [popImageTotalEditView, setPopImageTotalEditView] = useState(_jsx(ImageTotalEditPop, { popId: null, popBody: null }));
    const [popUserApprovalView, setPopUserApprovalView] = useState(_jsx(UserApprovalPop, { popId: null, popBody: null }));
    const [popUserApprovalEditView, setPopUserApprovalEditView] = useState(_jsx(UserApprovalEditPop, { popId: null, popBody: null }));
    const [popRoomApprovalView, setPopRoomApprovalView] = useState(_jsx(RoomApprovalPop, { popId: null, popBody: null }));
    const [popRoomApprovalView1, setPopRoomApprovalView1] = useState(_jsx(RoomApprovalPop1, { popId: null, popBody: null }));
    const [popRoomApprovalView2, setPopRoomApprovalView2] = useState(_jsx(RoomApprovalPop2, { popId: null, popBody: null }));
    const [popRoomCreateView, setPopRoomCreateView] = useState(_jsx(RoomCreatePop, { popId: null, popBody: null }));
    const [popConsultView, setPopConsultView] = useState(_jsx(ConsultPop, { popId: null, popBody: null }));
    const [popConsultEndView, setPopConsultEndView] = useState(_jsx(ConsultEndPop, { popId: null, popBody: null }));
    const [popSiteInfoView, setPopSiteInfoView] = useState(_jsx(SiteInfoPop, { popId: null, popBody: null }));
    const [popSiteEditView, setPopSiteEditView] = useState(_jsx(SiteEditPop, { popId: null, popBody: null }));
    const [popUserPassword, setPopUserPassword] = useState(_jsx(UserPasswordPop, { popBody: null }));
    const [popUserEnabled, setPopUserEnabled] = useState(_jsx(UserEnabledPop, { popBody: null }));
    const [popUserRole, setPopUserRole] = useState(_jsx(UserRolePop, { popBody: null }));
    const [popSolutionGroupBAdd, setPopSolutionGroupBAdd] = useState(_jsx(SolutionGroupBAddPop, { popBody: null }));
    const [popSolutionGroupBEdit, setPopSolutionGroupBEdit] = useState(_jsx(SolutionGroupBEditPop, { popBody: null }));
    const [popSolutionGroupBDel, setPopSolutionGroupBDel] = useState(_jsx(SolutionGroupBDelPop, { popBody: null }));
    const [popSolutionGroupMAdd, setPopSolutionGroupMAdd] = useState(_jsx(SolutionGroupMAddPop, { popBody: null }));
    const [popSolutionGroupMEdit, setPopSolutionGroupMEdit] = useState(_jsx(SolutionGroupMEditPop, { popBody: null }));
    const [popSolutionGroupMDel, setPopSolutionGroupMDel] = useState(_jsx(SolutionGroupMDelPop, { popBody: null }));
    const [popSolutionGroupSAdd, setPopSolutionGroupSAdd] = useState(_jsx(SolutionGroupSAddPop, { popBody: null }));
    const [popSolutionGroupSEdit, setPopSolutionGroupSEdit] = useState(_jsx(SolutionGroupSEditPop, { popBody: null }));
    const [popSolutionGroupSDel, setPopSolutionGroupSDel] = useState(_jsx(SolutionGroupSDelPop, { popBody: null }));
    const [popSolutionCardAdd, setPopSolutionCardAdd] = useState(_jsx(SolutionCaradAddPop, { popBody: null }));
    const [popSolutionCardEdit, setPopSolutionCardEdit] = useState(_jsx(SolutionCaradEditPop, { popBody: null }));
    const [popNewCompanyView, setPopNewCompanyView] = useState(_jsx(NewCompanyPop, { popBody: null }));
    const [popPauseAccount, setPopPauseAccount] = useState(_jsx(PauseAccount, { popId: null, popBody: null }));
    const [popStopPauseAccount, setPopStopPauseAccount] = useState(_jsx(StopPauseAccount, { popId: null, popBody: null }));
    const [popCategoryPop, setPopCategoryPop] = useState(_jsx(CategoryPop, { popId: null, popBody: null }));
    const [popCommon, setPopCommon] = useState(_jsx(CommonPop, { popId: null, popBody: null }));
    const [popCommon2, setPopCommon2] = useState(_jsx(CommonPop2, { popId: null, popBody: null }));
    //공통 팝업
    const [popConfirmView, setPopConfirmView] = useState(_jsx(ConfirmPop, { popId: null, popBody: null }));
    const [popAlertView, setPopAlertView] = useState(_jsx(AlertPop, { popId: null, popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(_jsx(EhpPostCode, { popBody: null }));
    // const [popInfo ,set]
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
        }
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu) => {
            if (smenu.url === urlInfo)
                curMenu = smenu;
        });
        setMenuCodeRecoil((curMenu) && curMenu.parentCode);
        const tempMenu = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu) => {
            tempMenu.push({
                "menuName": smenu.menuName,
                "menuUrl": smenu.url,
            });
        });
        setMenuList(tmenuList);
        if (!recoilNoneUrlInfo) {
            //초기화
            setRecoilData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilTreeData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: true },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilAdminData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "", },
                room: { roomId: "", roomName: "", },
            });
            setCheck({
                checkType: "daily",
                checkOn: false,
                nextStep: "",
                preStep: "",
                assessmentId: -1,
                checkitemId: -1,
                preCheckitemId: -1,
                checkvalueId: -1,
                itemNum: -1,
                totalComment: "",
                itemInfo: {
                    id: -1,
                    itemId: "",
                    itemName: "",
                    panelName: "",
                    serialNo: "",
                    spgId: -1
                },
                chekTypeInfo: {
                    checkTypeId: -1,
                    checkTypeName: "",
                },
                chekSubTypeInfo: {
                    checkSubTypeId: -1,
                    checkSubName: "",
                },
                chekVersionTypeInfo: {
                    checkVersionId: -1,
                    versionNo: "",
                },
                assessmentInfo: {
                    isTempSave: true,
                    reportId: -1,
                },
                checkvalue: {
                    action: "",
                    comment: "",
                    input: -1,
                    isChecked: false,
                    value: -1,
                },
                listType: -1,
                processRate: -1,
                startPage: -1,
                endpage: -1,
                dueDate: "",
                instrument: -1,
                countOpenClose: -1,
                treeName: "",
                schdeulerId: -1,
                disabled: false,
                period: -1,
                checkRef: [],
                isReportUpdated: false,
                inspector: "",
            });
            setRecoilState({
                companyId: "",
                zoneId: "",
            });
            setReContactusState(null);
        }
    }, [urlInfo, menuRecoilList]);
    useEffect(() => {
        setPopListView(popListView);
        setPopListView2(popListView2);
        setPopMemoView(popMomeView);
        setPopDeleteView(popDeleteView);
        setPopSearchView(popSearchView);
        setPopGuideView(popGuideView);
        setPopCheckSheetView(popCheckSheetView);
        setPopCheckCloseView(popCheckCloseView);
        setPopInstrumentAddView(popInstrumentAddView);
        setPopInstrumentEditView(popInstrumentEditView);
        setPopCheckOpenCloseView(popCheckOpenCloseView);
        setPopImageEditView(popImageEditView);
        setPopImageDeleteView(popImageDeleteView);
        setPopImageView(popImageView);
        setPopImageTotalEditView(popImageTotalEditView);
        setPopUserApprovalView(popUserApprovalView);
        setPopUserApprovalEditView(popUserApprovalEditView);
        setPopRoomCreateView(popRoomCreateView);
        setPopConsultView(popConsultView);
        setPopConsultEndView(popConsultEndView);
        setPopSiteInfoView(popSiteInfoView);
        setPopSiteEditView(popSiteEditView);
        setPopDeleteView2(popDeleteView2);
        setPopRoomApprovalView(popRoomApprovalView);
        setPopRoomApprovalView1(popRoomApprovalView1);
        setPopRoomApprovalView2(popRoomApprovalView2);
        setPopUserPassword(popUserPassword);
        setPopUserEnabled(popUserEnabled);
        setPopUserRole(popUserRole);
        setPopSolutionGroupBAdd(popSolutionGroupBAdd);
        setPopSolutionGroupMAdd(popSolutionGroupMAdd);
        setPopSolutionGroupSAdd(popSolutionGroupSAdd);
        setPopSolutionGroupBEdit(popSolutionGroupBEdit);
        setPopSolutionGroupMEdit(popSolutionGroupMEdit);
        setPopSolutionGroupSEdit(popSolutionGroupSEdit);
        setPopSolutionGroupBDel(popSolutionGroupBDel);
        setPopSolutionGroupMDel(popSolutionGroupMDel);
        setPopSolutionGroupSDel(popSolutionGroupSDel);
        setPopSolutionCardAdd(popSolutionCardAdd);
        setPopSolutionCardEdit(popSolutionCardEdit);
        setPopNewCompanyView(popNewCompanyView);
        setPopPauseAccount(popPauseAccount);
        setPopStopPauseAccount(popStopPauseAccount);
        setPopCategoryPop(popCategoryPop);
        setPopCommon(popCommon);
        setPopCommon2(popCommon2);
        // 공통
        setPopConfirmView(popConfirmView);
        setPopAlertView(popAlertView);
        setPopPostCode(popPostCode);
    }, [popConfirmView, popAlertView, popListView, popListView2, popGuideView,
        popMomeView, popSearchView, popCheckSheetView, popCheckCloseView,
        popDeleteView, popInstrumentAddView, popInstrumentEditView, popCheckOpenCloseView,
        popImageEditView, popImageDeleteView, popImageView, popImageTotalEditView,
        popUserApprovalView, popUserApprovalEditView, popRoomCreateView,
        popRoomApprovalView, popRoomApprovalView1, popRoomApprovalView2,
        popSiteInfoView, popSiteEditView, popDeleteView2, popUserPassword,
        popUserEnabled, popUserRole,
        popSolutionGroupBAdd, popSolutionGroupMAdd, popSolutionGroupSAdd,
        popSolutionGroupBEdit, popSolutionGroupMEdit, popSolutionGroupSEdit,
        popSolutionGroupBDel, popSolutionGroupMDel, popSolutionGroupSDel,
        popSolutionCardAdd, popSolutionCardEdit, popNewCompanyView,
        popPauseAccount, popStopPauseAccount, popCategoryPop,
        popCommon, popCommon2,
        // 공통
        popConsultView, popConsultEndView, popPostCode,
    ]);
    function handleOpnePop(popId, popBody) {
        if (popId === "pop-s-add") {
            setPopListView(_jsx(ListViewPopup, { popBody: popBody }));
        }
        if (popId === "pop-ss-add") {
            setPopListView2(_jsx(ListView2Popup, { popBody: popBody }));
        }
        else if (popId === "pop-guide") {
            setPopGuideView(_jsx(GuidePop, { popBody: popBody }));
        }
        else if (popId === "pop-memo") {
            setPopMemoView(_jsx(MemoPop, { popBody: popBody }));
        }
        else if (popId === "pop-delete") {
            setPopDeleteView(_jsx(DeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-delete2") {
            setPopDeleteView2(_jsx(DeletePop2, { popBody: popBody }));
        }
        else if (popId === "pop-search-small") {
            setPopSearchView(_jsx(SearchPop, { popBody: popBody }));
        }
        else if (popId === "pop-g-add") {
            setPopCheckSheetView(_jsx(CheckSheetPop, { popBody: popBody }));
        }
        else if (popId === "pop-check-close") {
            setPopCheckCloseView(_jsx(CheckClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-add-info") {
            setPopInstrumentAddView(_jsx(CheckInstrumentAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-info") {
            setPopInstrumentEditView(_jsx(CheckInstrumentEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-num") {
            setPopCheckOpenCloseView(_jsx(CheckOpenClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-img") {
            setPopImageView(_jsx(ImagePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-image") {
            setPopImageEditView(_jsx(ImageEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-delete-image") {
            setPopImageDeleteView(_jsx(ImageDeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-img") {
            setPopImageTotalEditView(_jsx(ImageTotalEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-admin-ok") {
            setPopUserApprovalView(_jsx(UserApprovalPop, { popBody: popBody }));
        }
        else if (popId === "pop-admin-edit") {
            setPopImageTotalEditView(_jsx(UserApprovalEditPop, { popBody: popBody }));
        }
        else if (popId == "pop-company-ok") {
            setPopRoomApprovalView(_jsx(RoomApprovalPop, { popBody: popBody }));
        }
        else if (popId == "pop-company-ok1") {
            setPopRoomApprovalView1(_jsx(RoomApprovalPop1, { popBody: popBody }));
        }
        else if (popId == "pop-company-ok2") {
            setPopRoomApprovalView2(_jsx(RoomApprovalPop2, { popBody: popBody }));
        }
        else if (popId == "pop-user-add") {
            setPopRoomCreateView(_jsx(RoomCreatePop, { popBody: popBody }));
        }
        else if (popId == "pop-answer") {
            setPopConsultView(_jsx(ConsultPop, { popBody: popBody }));
        }
        else if (popId == "pop-answer-end") {
            setPopConsultEndView(_jsx(ConsultEndPop, { popBody: popBody }));
        }
        else if (popId == "pop-listbox-detail") {
            setPopSiteInfoView(_jsx(SiteInfoPop, { popBody: popBody }));
        }
        else if (popId == "pop-listbox-detail-edit") {
            setPopSiteEditView(_jsx(SiteEditPop, { popBody: popBody }));
        }
        else if (popId == "pop-password") {
            setPopUserPassword(_jsx(UserPasswordPop, { popBody: popBody }));
        }
        else if (popId == "pop-enabled") {
            setPopUserEnabled(_jsx(UserEnabledPop, { popBody: popBody }));
        }
        else if (popId == "pop-role") {
            setPopUserRole(_jsx(UserRolePop, { popBody: popBody }));
        }
        else if (popId == "pop-a-1") {
            setPopSolutionGroupBAdd(_jsx(SolutionGroupBAddPop, { popBody: popBody }));
        }
        else if (popId == "pop-e-1") {
            setPopSolutionGroupBEdit(_jsx(SolutionGroupBEditPop, { popBody: popBody }));
        }
        else if (popId == "pop-d-1") {
            setPopSolutionGroupBDel(_jsx(SolutionGroupBDelPop, { popBody: popBody }));
        }
        else if (popId == "pop-a-2") {
            setPopSolutionGroupMAdd(_jsx(SolutionGroupMAddPop, { popBody: popBody }));
        }
        else if (popId == "pop-e-2") {
            setPopSolutionGroupMEdit(_jsx(SolutionGroupMEditPop, { popBody: popBody }));
        }
        else if (popId == "pop-d-2") {
            setPopSolutionGroupMDel(_jsx(SolutionGroupMDelPop, { popBody: popBody }));
        }
        else if (popId == "pop-a-3") {
            setPopSolutionGroupSAdd(_jsx(SolutionGroupSAddPop, { popBody: popBody }));
        }
        else if (popId == "pop-e-3") {
            setPopSolutionGroupSEdit(_jsx(SolutionGroupSEditPop, { popBody: popBody }));
        }
        else if (popId == "pop-d-3") {
            setPopSolutionGroupSDel(_jsx(SolutionGroupSDelPop, { popBody: popBody }));
        }
        else if (popId == "pop-add-card") {
            setPopSolutionCardAdd(_jsx(SolutionCaradAddPop, { popBody: popBody }));
        }
        else if (popId == "pop-edit-card") {
            setPopSolutionCardEdit(_jsx(SolutionCaradEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-clear") {
            setPopPauseAccount(_jsx(PauseAccount, { popBody: popBody }));
        }
        else if (popId === "pop-stopclear") {
            setPopStopPauseAccount(_jsx(StopPauseAccount, { popBody: popBody }));
        }
        else if (popId === "pop-cate") {
            setPopCategoryPop(_jsx(CategoryPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmm") {
            setPopCommon(_jsx(CommonPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmm2") {
            setPopCommon2(_jsx(CommonPop2, { popBody: popBody }));
        }
        // 공통 팝업
        else if (popId === "pop-confirm") {
            setPopConfirmView(_jsx(ConfirmPop, { popBody: popBody }));
        }
        else if (popId === "pop-alert") {
            setPopAlertView(_jsx(AlertPop, { popBody: popBody }));
        }
        else if (popId === "pop-postcode") {
            setPopPostCode(_jsx(EhpPostCode, { popBody: popBody }));
        }
        else if (popId === "pop-company-new") {
            setPopNewCompanyView(_jsx(NewCompanyPop, { popBody: popBody }));
        }
    }
    useEffect(() => {
        const handleClick = (e) => {
            const targetElement = e.target;
            const isSelect = targetElement.classList.contains("select") || targetElement.closest(".select");
            if (isSelect) {
                return;
            }
            const allSelectBoxElements = document.querySelectorAll(".select");
            allSelectBoxElements.forEach((boxElement) => {
                boxElement.classList.remove("active");
            });
        };
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap", className: `no-responsive ${(langs == CONST.STR_LANG_ENG) ? "lang-eng" : ""}` }, { children: [_jsx(Header, {}), (menuList) && (urlInfo) &&
                    _jsx(ManagementMain, { handleOpnePop: handleOpnePop, urlInfo: urlInfo, menuList: menuList }), popListView, popListView2, popGuideView, popMomeView, popDeleteView, popDeleteView2, popSearchView, popCheckSheetView, popCheckCloseView, popInstrumentAddView, popInstrumentEditView, popCheckOpenCloseView, popImageView, popImageEditView, popImageDeleteView, popImageTotalEditView, popUserApprovalView, popUserApprovalEditView, popRoomApprovalView, popRoomApprovalView1, popRoomApprovalView2, popRoomCreateView, popConsultView, popConsultEndView, popSiteInfoView, popSiteEditView, popUserPassword, popUserEnabled, popUserRole, popSolutionGroupBAdd, popSolutionGroupBEdit, popSolutionGroupBDel, popSolutionGroupMAdd, popSolutionGroupMEdit, popSolutionGroupMDel, popSolutionGroupSAdd, popSolutionGroupSEdit, popSolutionGroupSDel, popSolutionCardAdd, popSolutionCardEdit, popNewCompanyView, popPauseAccount, popStopPauseAccount, popCategoryPop, popCommon, popCommon2, popConfirmView, popAlertView, popPostCode] })) }));
}
export default ManagementLayout;
function ListViewPopup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-s-add", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ListView2Popup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-ss-add", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function MemoPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.메모") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function DeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function DeletePop2(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete2", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function GuidePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-guide", className: "popup-layer js-layer hidden popup-type2 w1496 h740 scrollH-no" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsxs("h1", { children: [t("라벨.점검가이드"), " "] }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SearchPop(props) {
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-search-small", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uAC80\uC0C9" })), _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckSheetPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-g-add", className: "popup-layer js-layer hidden popup-type2 w1496 h740" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckClosePop(props) {
    const t = useTrans();
    // body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-check-close", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-check-close") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckInstrumentAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckInstrumentEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckOpenClosePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-num", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-image", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageDeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete-image", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete-image") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImagePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-img", className: "popup-layer js-layer hidden popup-type1 wide" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlClose) && htmlClose, _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageTotalEditPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-img", className: "popup-layer js-layer hidden popup-type1 w630" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.현장사진추가") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-edit-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function UserApprovalPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-admin-ok", className: "popup-layer js-layer hidden popup-type2 w1200" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function UserApprovalEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-admin-edit", className: "popup-layer js-layer hidden popup-type2 w1200" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function RoomApprovalPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-company-ok", className: "popup-layer js-layer hidden popup-type2 w964" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function RoomCreatePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-user-add", className: "popup-layer js-layer hidden popup-type1 inner-popup w1200" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function ConsultPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-answer", className: "popup-layer js-layer hidden popup-type2 w1496 h90vh" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uACE0\uAC1D \uC694\uCCAD \uB2F5\uBCC0" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function ConsultEndPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-answer-end", className: "popup-layer js-layer hidden popup-type2 w1496 h90vh" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uACE0\uAC1D \uC694\uCCAD \uB2F5\uBCC0" }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-answer-end") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SiteInfoPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-listbox-detail", className: "popup-layer js-layer hidden popup-type1 page-detail workplace-ok" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC0AC\uC5C5\uC7A5 \uC0C1\uC138 \uC815\uBCF4" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SiteEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-listbox-detail-edit", className: "popup-layer js-layer hidden popup-type1 inner-popup page-detail workplace-ok" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC0AC\uC5C5\uC7A5 \uC815\uBCF4 \uC218\uC815" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function RoomApprovalPop1(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-company-ok1", className: "popup-layer js-layer hidden popup-type2 w964" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function RoomApprovalPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-company-ok2", className: "popup-layer js-layer hidden popup-type2 w964" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function UserPasswordPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-password", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function UserEnabledPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-enabled", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function UserRolePop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-role", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SolutionGroupBAddPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-a-1", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupBEditPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-e-1", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupBDelPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-d-1", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupMAddPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-a-2", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupMEditPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-e-2", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupMDelPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-d-2", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupSAddPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-a-3", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupSEditPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-e-3", className: "popup-layer js-layer hidden popup-type1 w658" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionGroupSDelPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-d-3", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function SolutionCaradAddPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-card", className: "popup-layer js-layer hidden popup-type2 w1080" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC194\uB8E8\uC158 \uCE74\uB4DC \uCD94\uAC00" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SolutionCaradEditPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-card", className: "popup-layer js-layer hidden popup-type2 w1080" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC194\uB8E8\uC158 \uCE74\uB4DC \uC218\uC815" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function NewCompanyPop(props) {
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-company-new", className: "popup-layer js-layer hidden popup-type1 inner-popup w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC2E0\uADDC \uD68C\uC0AC \uC815\uBCF4" }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => CUTIL.jsclose_InPopup("pop-company-new") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function PauseAccount(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function StopPauseAccount(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function CategoryPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function CommonPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function CommonPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function jscloses_Popup(id) {
    CUTIL.jsclose_Popup(id);
}
