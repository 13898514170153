var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import * as CONST from "../../../utils/Const";
import * as CUTIL from "../../../utils/commUtils";
import * as HTTPUTIL from "../../../utils/api/HttpUtil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { communityPageState, myPageBoardSearchState, selectedCommunityId, userInfoLoginState } from "../../../recoil/userState";
import { alertState } from "../../../recoil/assessmentState";
import { loadingBoxState } from "../../../recoil/menuState";
import { useNavigate } from "react-router-dom";
import { useTrans } from "../../../utils/langs/useTrans";
import { langId } from "../../../recoil/langState";
export default function MypageTempBoard(props) {
    const navigate = useNavigate();
    const t = useTrans();
    //recoil
    const userInfo = useRecoilValue(userInfoLoginState);
    const [alert, setAlert] = useRecoilState(alertState);
    const loadingBox = useSetRecoilState(loadingBoxState);
    const [communityId, setCommunityId] = useRecoilState(selectedCommunityId);
    const [communityState, setCommunityPageState] = useRecoilState(communityPageState);
    const langIds = useRecoilValue(langId);
    //
    let [pageInfo, setPageInfo] = useState({
        totalElements: 0,
        totalPages: 0,
        number: 0,
        size: 10
    });
    const [searchParam, setSearchParam] = useRecoilState(myPageBoardSearchState);
    const [tempList, setTempList] = useState([{
            categories: {
                code: "",
                codeName: "",
                colorSet: "",
                id: 0,
                role: "",
            },
            communityId: 0,
            contentText: "",
            createdTime: "",
            hashtags: [{
                    id: 0,
                    name: ""
                }],
            isBanned: false,
            isTempSave: true,
            likesCount: 0,
            medal: 0,
            title: "",
            updatedTime: "",
            userProfile: {
                isNickNameAutomated: false,
                nickName: "",
                selfIntroduce: "",
                userId: 0,
                userImage: "",
                userRank: "",
            },
            viewCount: 0
        }]);
    const [isEmpty, setEmpty] = useState(false);
    useEffect(() => {
        setPageInfo({ number: 0, size: 10, totalElements: 0, totalPages: 0 });
        setSearchParam({ category: "", sort: "updatedTime,desc" });
    }, []);
    const obsRef = useRef(null);
    const preventRef = useRef(true);
    const endRef = useRef(false);
    useEffect(() => {
        setInterval(() => {
            const observer = new IntersectionObserver(obsHandler, { threshold: 0.5 });
            if (obsRef.current)
                observer.observe(obsRef.current);
            return () => { observer.disconnect(); };
        }, 2000);
    }, []);
    useEffect(() => {
        getList(null);
    }, []);
    function obsHandler(entries) {
        const target = entries[0];
        if (!endRef.current && target.isIntersecting && preventRef.current) {
            console.log("obs핸들러 실행");
            preventRef.current = false;
            getList("INFINITE_SCROLL");
        }
    }
    function openWindow(item) {
        if (!CUTIL.isnull(item)) {
            // navigate(`/community?communityId=${id}`);
            // let url = window.location.origin + `/community?tempId=${item}`;
            // window.open(url, "_blank");
            setCommunityId(item);
            setCommunityPageState("WRITE");
            navigate(CONST.URL_COMMUNITY + `?tempId=${item}&prvPage=TEMP_BOARD`);
        }
    }
    function getList(option) {
        return __awaiter(this, void 0, void 0, function* () {
            let appPath = "";
            let searchPage = { totalElements: 0, totalPages: 0, number: 0, size: 10 };
            setPageInfo(prevState => { searchPage = prevState; return Object.assign({}, prevState); });
            if (option == "INFINITE_SCROLL") {
                appPath = `page=${searchPage.number + 1}&size=10&sort=updatedTime,desc`;
            }
            else {
                appPath = `page=0&size=10&sort=updatedTime,desc`;
            }
            let ListData = yield HTTPUTIL.PromiseHttp({
                httpMethod: "GET",
                appPath: `/api/v3/community/mypagelist?isTempSave=1&${appPath}`,
                appQuery: {},
                userToken: userInfo.loginInfo.token,
            });
            const ERR_URL = HTTPUTIL.resultCheck(true, ListData);
            if (ERR_URL.length > 0)
                navigate(ERR_URL);
            if (ListData) {
                if (ListData.codeNum == CONST.API_200) {
                    let res = ListData.body;
                    if (CUTIL.isnull(res)) {
                        setEmpty(true);
                    }
                    else {
                        let page = ListData.data.page;
                        setPageInfo(page);
                        if (option == "INFINITE_SCROLL") {
                            console.log("Infinite Scroll");
                            preventRef.current = true;
                            setTempList(list => [...list, ...res]);
                            if ((page.number + 1) * page.size >= page.totalElements) {
                                endRef.current = true;
                            }
                        }
                        else {
                            setTempList(ListData.body);
                            if ((page.number + 1) * page.size >= page.totalElements) {
                                endRef.current = true;
                            }
                            else {
                                endRef.current = false;
                            }
                        }
                    }
                }
            }
        });
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "item-list-top" }, { children: _jsx("div", Object.assign({ className: "left" }, { children: _jsx("p", Object.assign({ className: "tit" }, { children: t("커뮤니티.임시저장글") })) })) })), _jsx("div", { children: _jsxs("ul", Object.assign({ className: "list-area" }, { children: [(tempList.length != 0 && tempList[0].updatedTime != "") && tempList.map((item, index) => (_jsxs("li", { children: [_jsxs("div", Object.assign({ className: "list-top" }, { children: [_jsx("div", Object.assign({ className: "left" }, { children: _jsx("p", { children: item.title }) })), _jsx("div", Object.assign({ className: "right" }, { children: _jsx("p", { children: CUTIL.setTodayDate(item.updatedTime, langIds) }) }))] })), _jsx("div", Object.assign({ className: "list-bottom" }, { children: _jsxs("a", Object.assign({ onClick: (e) => openWindow(item.communityId) }, { children: [_jsxs("div", Object.assign({ className: "top" }, { children: [_jsx("p", Object.assign({ className: `cate ${CUTIL.getCategoryClass(item.categories.colorSet)}` }, { children: item.categories.codeName })), _jsx("p", { children: item.title })] })), _jsx("div", Object.assign({ className: "bottom" }, { children: _jsx("p", { children: (CUTIL.isnull(item.contentText) ? "내용 없음" : item.contentText) }) }))] })) }))] }))), (isEmpty ?
                            _jsx("div", Object.assign({ className: "nodata" }, { children: _jsx("p", Object.assign({ className: "nodata__txt" }, { children: t("커뮤니티.NODATA") })) })) : "")] })) }), _jsx("div", { style: { position: "absolute", top: "95%" }, ref: obsRef })] }));
}
