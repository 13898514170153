import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url 기본URL("/"), /whatewdo, /marketplace, /contactus, /community, /
 * @CONST CONST.URL_MAIN, CONST.URL_WHATWEDO, CONST.URL_MARKETPLACE, CONST.URL_CONTACTUS, CONST.URL_COMMUNITY, CONST.URL_LOGININTRO
 * @menu  마켓플레이스
 * @mapping 서비스 소개 화면 , Wath We Do 화면, 마켓플레이스 화면, 랜딩 상담신청 , 랜딩 로그인
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
// css
import "/static/css/style.css";
// ex-module
import { urlState, menuListState, parentCode, checkSheetState, detail, loadingBoxState, marketWork, detailHistory, detailList, contactusState, } from "../../recoil/menuState";
import { useNavigate } from "react-router-dom";
import * as CUTIL from "../../utils/commUtils";
import * as CONST from "../../utils/Const";
import AlertPop from "../common/popup/AlertPop";
import ConfirmPop from "../common/popup/ConfirmPop";
import EhpPostCode from "../common/postcode/EhpPostCode";
import HeaderIntro from "../headerIntro/HeaderIntro";
import IntroductionMain from "../introductions/IntroductionMain";
import LandingPop from "../common/popup/LandingPop";
import { communityPageState, reloadRight } from "../../recoil/userState";
import { curSchduleTreeState, curMonitoringTreeState, curAdminTreeState, checkTypeState, reportTypeState, curReportState, noneUrl, todayState, deviceState, mainState } from "../../recoil/assessmentState";
// Community Join
import Logout from "../headerIntro/Logout";
import MyAccount from "../headerIntro/MyAccount";
import LandingLoginPop from "../common/popup/LandingLoginPop";
import CmmJoinPop from "../common/popup/communityjoin/CmmJoinPop";
import CmmJoinTermPop from "../common/popup/communityjoin/CmmJoinTermPop";
import CmmFindIdPop from "../common/popup/communityjoin/CmmFindIdPop";
import CmmFindPwdPop from "../common/popup/communityjoin/CmmFindPwdPop";
import CmmJoinCompanyPop from "../common/popup/communityjoin/CmmJoinCompanyPop";
import CmmJoinNewCompanyPop from "../common/popup/communityjoin/CmmJoinNewCompanyPop";
import CmmJoinDonePop from "../common/popup/communityjoin/CmmJoinDonePop";
import CmmConfirmPop2 from "../common/popup/CmmConfirmPop2";
import CmmLinkageJoinPop from "../common/popup/communityjoin/CmmLinkageJoinPop";
import AutoNicknamePop from "../common/popup/AutoNicknamePop";
import ChangePwdPop from "../common/popup/communityjoin/ChangePwdPop";
import ChangeMyInfoPop from "../common/popup/communityjoin/ChangeMyInfoPop";
import FooterIntro from "../footerIntro/FooterIntro";
import CertificationLoginPop from "../common/popup/CertificationLoginPop";
import CmmJoinTermDataCollectionPop from "../common/popup/communityjoin/Treams/CmmJoinTermDataCollectionPop";
import CmmJoinTermPrivacyPolicyPop from "../common/popup/communityjoin/Treams/CmmJoinTermPrivacyPolicyPop";
import CmmJoinTermSystemPop from "../common/popup/communityjoin/Treams/CmmJoinTermSystemPop";
import CmmJoinTermDataCollectionOldPop from "../common/popup/communityjoin/Treams/old/CmmJoinTermDataCollectionOldPop";
import CmmJoinTermSystemOldPop from "../common/popup/communityjoin/Treams/old/CmmJoinTermSystemOldPop";
import { langState } from "../../recoil/langState";
import { useTrans } from "../../utils/langs/useTrans";
/**
 * @param props0 handleOpnePop : 레이어 팝업
 */
function IntroductionLayout() {
    const navigate = useNavigate();
    // recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const langs = useRecoilValue(langState); // recoil langState
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const setCheckSheet = useSetRecoilState(checkSheetState);
    const setRecoilTreeData = useSetRecoilState(curSchduleTreeState);
    const setRecoilData = useSetRecoilState(curMonitoringTreeState);
    const setRecoilAdminData = useSetRecoilState(curAdminTreeState);
    const setMenuCodeRecoil = useSetRecoilState(parentCode);
    const [check, setCheck] = useRecoilState(checkTypeState);
    const report = useRecoilValue(reportTypeState);
    const setRecoilReportData = useSetRecoilState(curReportState);
    const setRecoilState = useSetRecoilState(mainState);
    const setReroadRights = useSetRecoilState(reloadRight);
    const recoilNoneUrlInfo = useRecoilValue(noneUrl);
    const setrecoilToday = useSetRecoilState(todayState);
    const [deviceInfo, setDeviceInfo] = useRecoilState(deviceState);
    const [loadingBox, setLoadingBox] = useRecoilState(loadingBoxState);
    const setRecoilMarkerWorks = useSetRecoilState(marketWork);
    const setRecoilHistory = useSetRecoilState(detailHistory);
    const setRecoilDetailList = useSetRecoilState(detailList);
    const setReContactusState = useSetRecoilState(contactusState);
    const [recoilCommunityPage, setRecoilCommunityPageState] = useRecoilState(communityPageState);
    //
    const [menuList, setMenuList] = useState(null);
    const [urlList, setUrlList] = useState(null);
    const [details, setDetils] = useRecoilState(detail);
    //
    const [popListView, setPopListView] = useState(_jsx(ListViewPopup, { popBody: null }));
    const [popListView2, setPopListView2] = useState(_jsx(ListView2Popup, { popBody: null }));
    const [popListView3, setPopListView3] = useState(_jsx(ListView3Popup, { popBody: null }));
    const [popUpdateView, setPopUpdateView] = useState(_jsx(ItemUdatePop, { popId: null, popBody: null }));
    const [popTrendView, setPopTrendView] = useState(_jsx(TrendPop, { popId: null, popBody: null }));
    const [popMomeView, setPopMemoView] = useState(_jsx(MemoPop, { popId: null, popBody: null }));
    const [popMomeView2, setPopMemoView2] = useState(_jsx(MemoPop2, { popId: null, popBody: null }));
    const [popDeleteView, setPopDeleteView] = useState(_jsx(DeletePop, { popId: null, popBody: null }));
    const [popSearchView, setPopSearchView] = useState(_jsx(SearchPop, { popId: null, popBody: null }));
    const [popShareView, setPopShareView] = useState(_jsx(SharePop, { popId: null, popBody: null }));
    const [popCheckUpdateView, setPopCheckUpdateView] = useState(_jsx(CheckUdatePop, { popId: null, popBody: null }));
    const [popCheckCloseView, setPopCheckCloseView] = useState(_jsx(CheckClosePop, { popId: null, popBody: null }));
    const [popInstrumentAddView, setPopInstrumentAddView] = useState(_jsx(CheckInstrumentAddPop, { popId: null, popBody: null }));
    const [popInstrumentAddView2, setPopInstrumentAddView2] = useState(_jsx(CheckInstrumentAddPop2, { popId: null, popBody: null }));
    const [popInstrumentEditView, setPopInstrumentEditView] = useState(_jsx(CheckInstrumentEditPop, { popId: null, popBody: null }));
    const [popCheckOpenCloseView, setPopCheckOpenCloseView] = useState(_jsx(CheckOpenClosePop, { popId: null, popBody: null }));
    const [popImageView, setPopImageView] = useState(_jsx(ImagePop, { popId: null, popBody: null }));
    const [popImageEditView, setPopImageEditView] = useState(_jsx(ImageEditPop, { popId: null, popBody: null }));
    const [popImageDeleteView, setPopImageDeleteView] = useState(_jsx(ImageDeletePop, { popId: null, popBody: null }));
    const [popImageTotalEditView, setPopImageTotalEditView] = useState(_jsx(ImageTotalEditPop, { popId: null, popBody: null }));
    const [popReportiew, setPopReportView] = useState(_jsx(ReportPop, { popId: null, popBody: null }));
    const [popTitleEditView, setPopTitleEditView] = useState(_jsx(TitleEditPop, { popId: null, popBody: null }));
    const [popCompanyAddView, setPopCompanyAddView] = useState(_jsx(CompanyAddPop, { popId: null, popBody: null }));
    const [popDeviceAllView, setPopDeviceAllView] = useState(_jsx(DeviceAllPop, { popId: null, popBody: null }));
    const [popWhatWeDoView, setPopWhatWeDoView] = useState(_jsx(WhatWeDoPop, { popId: null, popBody: null }));
    const [popGuideRank, setPopGuideRank] = useState(_jsx(GuideRank, { popId: null, popBody: null }));
    const [popUpdateNickName, setPopUpdateNickName] = useState(_jsx(UpdateNickName, { popId: null, popBody: null }));
    const [popUpdateIntroduce, setPopUpdateIntroduce] = useState(_jsx(UpdateIntroduce, { popId: null, popBody: null }));
    const [popUpdateProfileImg, setPopUpdateProfileImg] = useState(_jsx(UpdateProfileImg, { popId: null, popBody: null }));
    //SEI2.0
    const [popChangePwdView, setPopChangePwdView] = useState(_jsx(ChangePwdPop, { popId: null, popBody: null }));
    const [popChangeMyInfoView, setPopChangeMyInfoView] = useState(_jsx(ChangeMyInfoPop, { popId: null, popBody: null }));
    // 커뮤니티 로그인
    const [popCmmFindIdView, setPopCmmFindIdView] = useState(_jsx(CmmFindIdPop, { popId: null, popBody: null }));
    const [popCmmFindPwdView, setPopCmmFindPwdView] = useState(_jsx(CmmFindPwdPop, { popId: null, popBody: null }));
    const [popCmmJoinView, setPopCmmJoinView] = useState(_jsx(CmmJoinPop, { popId: null, popBody: null }));
    const [popCmmJoinCompanyView, setPopCmmJoinCompanyView] = useState(_jsx(CmmJoinCompanyPop, { popId: null, popBody: null }));
    const [popCmmJoinNewCompanyView, setPopCmmJoinNewCompanyView] = useState(_jsx(CmmJoinNewCompanyPop, { popId: null, popBody: null }));
    const [popCmmJoinDoneView, setPopCmmJoinDoneView] = useState(_jsx(CmmJoinDonePop, { popId: null, popBody: null }));
    const [popCmmLinkageJoinView, setPopCmmLinkageJoinView] = useState(_jsx(CmmLinkageJoinPop, { popId: null, popBody: null }));
    const [popCmmJoinTremView, setPopCmmJoinTremView] = useState(_jsx(CmmJoinTermPop, { popId: null, popBody: null }));
    const [popCmmJoinTremSystemView, setPopCmmJoinTremSystemView] = useState(_jsx(CmmJoinTermSystemPop, { popId: null, popBody: null }));
    const [popCmmJoinTremPrivacyPolicyView, setPopCmmJoinTremPrivacyPolicyView] = useState(_jsx(CmmJoinTermPrivacyPolicyPop, { popId: null, popBody: null }));
    const [popCmmJoinTremDataCollectionView, setPopCmmJoinTremDataCollectionView] = useState(_jsx(CmmJoinTermDataCollectionPop, { popId: null, popBody: null }));
    const [popCmmJoinTremSystemOldView, setPopCmmJoinTremSystemOldView] = useState(_jsx(CmmJoinTermSystemOldPop, { popId: null, popBody: null }));
    const [popCmmJoinTremDataCollectionOldView, setPopCmmJoinTremDataCollectionOldView] = useState(_jsx(CmmJoinTermDataCollectionOldPop, { popId: null, popBody: null }));
    //공통 팝업
    const [popConfirmView, setPopConfirmView] = useState(_jsx(ConfirmPop, { popId: null, popBody: null }));
    const [popCmmConfirmView2, setPopCmmConfirmView2] = useState(_jsx(CmmConfirmPop2, { popId: null, popBody: null }));
    const [popAlertView, setPopAlertView] = useState(_jsx(AlertPop, { popId: null, popBody: null }));
    const [popLandingView, setPopLandingView] = useState(_jsx(LandingPop, { popId: null, popBody: null }));
    const [popLandingLoginView, setPopLandingLoginView] = useState(_jsx(LandingLoginPop, { popId: null, popBody: null }));
    const [popCertificationLoginView, setPopCertificationLoginView] = useState(_jsx(CertificationLoginPop, { popId: null, popBody: null }));
    const [popAutoNicknameView, setPopLAutoNicknameView] = useState(_jsx(AutoNicknamePop, { popId: null, popBody: null }));
    // 공통 - postcode
    const [popPostCode, setPopPostCode] = useState(_jsx(EhpPostCode, { popBody: null }));
    // const [popInfo ,set]
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        setUrlList(urlInfo);
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
        }
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu) => {
            if (smenu.url === urlInfo)
                curMenu = smenu;
        });
        setMenuCodeRecoil((curMenu) && curMenu.parentCode);
        const tempMenu = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu) => {
            tempMenu.push({
                "menuName": smenu.menuName,
                "menuUrl": smenu.url,
            });
        });
        setMenuList(tmenuList);
        if (!recoilNoneUrlInfo) {
            // 초기화
            setCheckSheet({
                sheetTyep: "",
                spgName: ""
            });
            //초기화
            setRecoilData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilTreeData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: true },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilAdminData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "", },
                room: { roomId: "", roomName: "", },
            });
            setCheck({
                checkType: "daily",
                checkOn: false,
                nextStep: "",
                preStep: "",
                assessmentId: -1,
                checkitemId: -1,
                preCheckitemId: -1,
                checkvalueId: -1,
                itemNum: -1,
                totalComment: "",
                itemInfo: {
                    id: -1,
                    itemId: "",
                    itemName: "",
                    panelName: "",
                    serialNo: "",
                    spgId: -1
                },
                chekTypeInfo: {
                    checkTypeId: -1,
                    checkTypeName: "",
                },
                chekSubTypeInfo: {
                    checkSubTypeId: -1,
                    checkSubName: "",
                },
                chekVersionTypeInfo: {
                    checkVersionId: -1,
                    versionNo: "",
                },
                assessmentInfo: {
                    isTempSave: true,
                    reportId: -1,
                },
                checkvalue: {
                    action: "",
                    comment: "",
                    input: -1,
                    isChecked: false,
                    value: -1,
                },
                listType: -1,
                processRate: -1,
                startPage: -1,
                endpage: -1,
                dueDate: "",
                instrument: -1,
                countOpenClose: -1,
                treeName: "",
                schdeulerId: -1,
                disabled: false,
                period: -1,
                checkRef: [],
                isReportUpdated: false,
                inspector: "",
            });
            setRecoilReportData({
                companyId: "",
                subZoneId: "",
                name: "",
                roomId: "",
                roomName: "",
                spgId: -1,
                spgName: ""
            });
            setrecoilToday(null);
            setDeviceInfo({
                id: 1,
                name: "등록된 설비"
            });
            setRecoilState({
                companyId: "",
                zoneId: ""
            });
            //
            setDetils(false);
            setRecoilMarkerWorks("LIST");
            setRecoilHistory({
                page: 0,
                bigGroup: "energy",
                searchKind: 0,
                searchLike: "",
                tagSearch: [],
                searchFilter: "",
                zone: false,
                zoneId: null,
            });
            setReContactusState(null);
            setRecoilDetailList(null);
            window.scrollTo({ top: 0 });
            setLoadingBox(false);
            setRecoilCommunityPageState("LIST");
        }
    }, [urlInfo,]);
    useEffect(() => {
        //SEI1.0
        setPopListView(popListView);
        setPopListView2(popListView2);
        setPopListView3(popListView3);
        setPopMemoView(popMomeView);
        setPopDeleteView(popDeleteView);
        setPopSearchView(popSearchView);
        setPopUpdateView(popUpdateView);
        setPopCheckUpdateView(popCheckUpdateView);
        setPopCheckCloseView(popCheckCloseView);
        setPopInstrumentAddView(popInstrumentAddView);
        setPopInstrumentEditView(popInstrumentEditView);
        setPopCheckOpenCloseView(popCheckOpenCloseView);
        setPopImageEditView(popImageEditView);
        setPopImageDeleteView(popImageDeleteView);
        setPopImageView(popImageView);
        setPopImageTotalEditView(popImageTotalEditView);
        setPopShareView(popShareView);
        setPopReportView(popReportiew);
        setPopTitleEditView(popTitleEditView);
        setPopTrendView(popTrendView);
        setPopInstrumentAddView2(popInstrumentAddView2);
        setPopCompanyAddView(popCompanyAddView);
        setPopMemoView2(popMomeView2);
        setPopDeviceAllView(popDeviceAllView);
        setPopWhatWeDoView(popWhatWeDoView);
        setPopGuideRank(popGuideRank);
        setPopUpdateNickName(popUpdateNickName);
        setPopUpdateIntroduce(popUpdateIntroduce);
        setPopUpdateProfileImg(popUpdateProfileImg);
        // SEI2.0
        setPopChangePwdView(popChangePwdView);
        setPopChangeMyInfoView(popChangeMyInfoView);
        //커뮤니티 로그인
        setPopCmmFindIdView(popCmmFindIdView);
        setPopCmmFindPwdView(popCmmFindPwdView);
        setPopCmmJoinView(popCmmJoinView);
        setPopCmmJoinTremView(popCmmJoinTremView);
        setPopCmmJoinCompanyView(popCmmJoinCompanyView);
        setPopCmmJoinNewCompanyView(popCmmJoinNewCompanyView);
        setPopCmmJoinDoneView(popCmmJoinDoneView);
        setPopCmmLinkageJoinView(popCmmLinkageJoinView);
        setPopCmmJoinTremSystemView(popCmmJoinTremSystemView);
        setPopCmmJoinTremPrivacyPolicyView(popCmmJoinTremPrivacyPolicyView);
        setPopCmmJoinTremDataCollectionView(popCmmJoinTremDataCollectionView);
        setPopCmmJoinTremSystemOldView(popCmmJoinTremSystemOldView);
        setPopCmmJoinTremDataCollectionOldView(popCmmJoinTremDataCollectionOldView);
        // 공통
        setPopConfirmView(popConfirmView);
        setPopCmmConfirmView2(popCmmConfirmView2);
        setPopAlertView(popAlertView);
        setPopPostCode(popPostCode);
        setPopLandingView(popLandingView);
        setPopLandingLoginView(popLandingLoginView);
        setPopLAutoNicknameView(popAutoNicknameView);
        setPopCertificationLoginView(popCertificationLoginView);
    }, [
        popListView, popListView2, popListView3, popUpdateView, popMomeView,
        popSearchView, popCheckUpdateView, popCheckCloseView, popDeleteView,
        popInstrumentAddView, popInstrumentEditView, popCheckOpenCloseView,
        popImageEditView, popImageDeleteView, popImageView, popImageTotalEditView,
        popShareView, popReportiew, popTitleEditView, popTrendView,
        popInstrumentAddView2, popCompanyAddView, popMomeView2, popDeviceAllView,
        popWhatWeDoView, popGuideRank, popUpdateNickName, popUpdateIntroduce,
        popUpdateProfileImg,
        // SIE2.0
        popChangePwdView, popChangeMyInfoView,
        //
        popCmmFindIdView, popCmmFindPwdView, popCmmJoinView, popCmmJoinTremView,
        popCmmJoinCompanyView, popCmmJoinNewCompanyView, popCmmJoinDoneView, popCmmLinkageJoinView,
        popCmmJoinTremSystemView, popCmmJoinTremPrivacyPolicyView, popCmmJoinTremDataCollectionView,
        popCmmJoinTremSystemOldView, popCmmJoinTremDataCollectionOldView,
        //
        popConfirmView, popAlertView, popPostCode, popLandingView, popLandingLoginView, popCmmConfirmView2, popAutoNicknameView,
        popCertificationLoginView,
    ]);
    function handleOpnePop(popId, popBody) {
        if (popId === "pop-s-add") {
            setPopListView(_jsx(ListViewPopup, { popBody: popBody }));
        }
        if (popId === "pop-ss-add") {
            setPopListView2(_jsx(ListView2Popup, { popBody: popBody }));
        }
        if (popId === "pop-sss-add") {
            setPopListView3(_jsx(ListView3Popup, { popBody: popBody }));
        }
        else if (popId === "pop-s-edit") {
            setPopUpdateView(_jsx(ItemUdatePop, { popBody: popBody }));
        }
        else if (popId === "pop-trend") {
            setPopTrendView(_jsx(TrendPop, { popBody: popBody }));
        }
        else if (popId === "pop-memo") {
            setPopMemoView(_jsx(MemoPop, { popBody: popBody }));
        }
        else if (popId === "pop-memo2") {
            setPopMemoView2(_jsx(MemoPop2, { popBody: popBody }));
        }
        else if (popId === "pop-delete") {
            setPopDeleteView(_jsx(DeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-search-small") {
            setPopSearchView(_jsx(SearchPop, { popBody: popBody }));
        }
        else if (popId === "pop-check-edit") {
            setPopCheckUpdateView(_jsx(CheckUdatePop, { popBody: popBody }));
        }
        else if (popId === "pop-check-close") {
            setPopCheckCloseView(_jsx(CheckClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-add-info") {
            setPopInstrumentAddView(_jsx(CheckInstrumentAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-info2") {
            setPopInstrumentAddView2(_jsx(CheckInstrumentAddPop2, { popBody: popBody }));
        }
        else if (popId === "pop-edit-info") {
            setPopInstrumentEditView(_jsx(CheckInstrumentEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-num") {
            setPopCheckOpenCloseView(_jsx(CheckOpenClosePop, { popBody: popBody }));
        }
        else if (popId === "pop-img") {
            setPopImageView(_jsx(ImagePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-image") {
            setPopImageEditView(_jsx(ImageEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-delete-image") {
            setPopImageDeleteView(_jsx(ImageDeletePop, { popBody: popBody }));
        }
        else if (popId === "pop-edit-img") {
            setPopImageTotalEditView(_jsx(ImageTotalEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-share") {
            setPopShareView(_jsx(SharePop, { popBody: popBody }));
        }
        else if (popId === "pop-report") {
            setPopReportView(_jsx(ReportPop, { popBody: popBody }));
        }
        else if (popId === "pop-tit-edit") {
            setPopTitleEditView(_jsx(TitleEditPop, { popBody: popBody }));
        }
        else if (popId === "pop-add-company") {
            setPopCompanyAddView(_jsx(CompanyAddPop, { popBody: popBody }));
        }
        else if (popId === "pop-finish-ok") {
            setPopDeviceAllView(_jsx(DeviceAllPop, { popBody: popBody }));
        }
        else if (popId === "pop-whatwedo") {
            setPopWhatWeDoView(_jsx(WhatWeDoPop, { popBody: popBody }));
        }
        else if (popId === "pop-rank") {
            setPopGuideRank(_jsx(GuideRank, { popBody: popBody }));
        }
        else if (popId === "pop-nickname") {
            setPopUpdateNickName(_jsx(UpdateNickName, { popBody: popBody }));
        }
        else if (popId === "pop-intoduce") {
            setPopUpdateIntroduce(_jsx(UpdateIntroduce, { popBody: popBody }));
        }
        else if (popId === "pop-profileImg") {
            setPopUpdateProfileImg(_jsx(UpdateProfileImg, { popBody: popBody }));
        }
        // SEI2.0
        else if (popId === "my-pwd") {
            setPopChangePwdView(_jsx(ChangePwdPop, { popBody: popBody }));
        }
        else if (popId === "my-info") {
            setPopChangeMyInfoView(_jsx(ChangeMyInfoPop, { popBody: popBody }));
        }
        // 커뮤니티 로그인
        else if (popId === "cmm-findId") {
            setPopCmmFindIdView(_jsx(CmmFindIdPop, { popBody: popBody }));
        }
        else if (popId === "cmm-findPw") {
            setPopCmmFindPwdView(_jsx(CmmFindPwdPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join") {
            setPopCmmJoinView(_jsx(CmmJoinPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmm-company") {
            setPopCmmJoinCompanyView(_jsx(CmmJoinCompanyPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmm-new-comp") {
            setPopCmmJoinNewCompanyView(_jsx(CmmJoinNewCompanyPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-ok") {
            setPopCmmJoinDoneView(_jsx(CmmJoinDonePop, { popBody: popBody }));
        }
        else if (popId === "cmm-linkage-join") {
            setPopCmmLinkageJoinView(_jsx(CmmLinkageJoinPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms") {
            setPopCmmJoinTremView(_jsx(CmmJoinTermPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms-system") {
            setPopCmmJoinTremSystemView(_jsx(CmmJoinTermSystemPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms-privacy") {
            setPopCmmJoinTremPrivacyPolicyView(_jsx(CmmJoinTermPrivacyPolicyPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms-data") {
            setPopCmmJoinTremDataCollectionView(_jsx(CmmJoinTermDataCollectionPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms-system-old") {
            setPopCmmJoinTremSystemOldView(_jsx(CmmJoinTermSystemOldPop, { popBody: popBody }));
        }
        else if (popId === "cmm-join-terms-data-old") {
            setPopCmmJoinTremDataCollectionOldView(_jsx(CmmJoinTermDataCollectionOldPop, { popBody: popBody }));
        }
        // 공통 팝업
        else if (popId === "pop-confirm") {
            setPopConfirmView(_jsx(ConfirmPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmmConfirm1") {
            setPopCmmConfirmView2(_jsx(CmmConfirmPop2, { popBody: popBody }));
        }
        else if (popId === "pop-alert") {
            setPopAlertView(_jsx(AlertPop, { popBody: popBody }));
        }
        else if (popId === "pop1") {
            setPopLandingView(_jsx(LandingPop, { popBody: popBody }));
        }
        else if (popId === "pop-login") {
            setPopLandingLoginView(_jsx(LandingLoginPop, { popBody: popBody }));
        }
        else if (popId === "pop-cmm-login") {
            setPopCertificationLoginView(_jsx(CertificationLoginPop, { popBody: popBody }));
        }
        else if (popId === "pop-postcode") {
            setPopPostCode(_jsx(EhpPostCode, { popBody: popBody }));
        }
        else if (popId === "pop-AutoNickname") {
            setPopLAutoNicknameView(_jsx(AutoNicknamePop, { popBody: popBody }));
        }
    }
    var body = document.body;
    if (!CUTIL.isnull(body)) {
        if (loadingBox) {
            body.classList.add("scrollH-no");
        }
        else {
            body.classList.remove("scrollH-no");
        }
    }
    useEffect(() => {
        const handleClick = (e) => {
            const targetElement = e.target;
            const isSelect = targetElement.classList.contains("select") || targetElement.closest(".select");
            if (isSelect) {
                return;
            }
            const allSelectBoxElements = document.querySelectorAll(".select");
            allSelectBoxElements.forEach((boxElement) => {
                boxElement.classList.remove("active");
            });
        };
        window.addEventListener('click', handleClick);
        return () => window.removeEventListener('click', handleClick);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ id: "wrap", className: `page-market 
      ${(details) ? "detail" : ""}
      ${(urlInfo == CONST.URL_LOGININTRO) ? "login" :
                    (urlInfo.split("?")[0] == CONST.URL_CONTACTUS) ? "consulting" :
                        (urlInfo == CONST.URL_MAIN) ? "serviceintro" :
                            (urlInfo == CONST.URL_WHATWEDO) ? "whatwedo" :
                                (urlInfo.split("?")[0] == CONST.URL_COMMUNITY) ? "community" :
                                    (urlInfo.split("?")[0] == CONST.URL_MYPAGE) ? "community" :
                                        (urlInfo.split("?")[0] == CONST.URL_VERIFYEMAILCOMMUNITY) ? "community" :
                                            ""}
                      ${(langs == CONST.STR_LANG_ENG) ? "lang-eng" : ""}
                      ` }, { children: [_jsx(HeaderIntro, { handleOpnePop: handleOpnePop }), _jsx(IntroductionMain, { handleOpnePop: handleOpnePop, urlInfo: urlInfo }), (urlInfo !== CONST.URL_COMMUNITY || recoilCommunityPage !== "LIST") &&
                        _jsx(FooterIntro, {}), popListView, popListView2, popListView3, popUpdateView, popMomeView, popMomeView2, popDeleteView, popSearchView, popCheckUpdateView, popCheckCloseView, popInstrumentAddView, popInstrumentAddView2, popInstrumentEditView, popCheckOpenCloseView, popImageView, popImageEditView, popImageDeleteView, popImageTotalEditView, popShareView, popReportiew, popTitleEditView, popTrendView, popCompanyAddView, popDeviceAllView, popWhatWeDoView, popGuideRank, popUpdateNickName, popUpdateIntroduce, popUpdateProfileImg, popChangePwdView, popChangeMyInfoView, popCmmFindIdView, popCmmFindPwdView, popCmmJoinView, popCmmJoinCompanyView, popCmmJoinNewCompanyView, popCmmJoinDoneView, popCmmLinkageJoinView, popCmmJoinTremView, popCmmJoinTremSystemView, popCmmJoinTremPrivacyPolicyView, popCmmJoinTremDataCollectionView, popCmmJoinTremSystemOldView, popCmmJoinTremDataCollectionOldView, popConfirmView, popCmmConfirmView2, popAlertView, popPostCode, popLandingView, popLandingLoginView, popAutoNicknameView, popCertificationLoginView, _jsx(Logout, {}), _jsx(MyAccount, {})] })), _jsx("div", { className: "dimm" }), _jsx("div", Object.assign({ className: (loadingBox) ? "loading" : "" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB85C\uB529\uC911" })) }))] }));
}
export default IntroductionLayout;
function ListViewPopup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-s-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ListView2Popup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-ss-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ListView3Popup(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-sss-add", className: "popup-layer js-layer hidden popup-type2 w1142" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.추가") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function MemoPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function MemoPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-memo2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function DeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ItemUdatePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-s-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function TrendPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-trend", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uC9C4\uB2E8\uC810\uAC80 Trend" }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SearchPop(props) {
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-search-small", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uAC80\uC0C9" })), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-search-small") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function SharePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-share", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uACF5\uC720" })), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-share") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckUdatePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-check-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckClosePop(props) {
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-check-close", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uB098\uAC00\uAE30" }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-check-close") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckInstrumentAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckInstrumentAddPop2(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-info2", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckInstrumentEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-info", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function CheckOpenClosePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-num", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageEditPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-image", className: "popup-layer js-layer hidden popup-type1 w350" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageDeletePop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-delete-image", className: "popup-layer js-layer hidden popup-basic" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.삭제") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-delete-image") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImagePop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-img", className: "popup-layer js-layer hidden popup-type1 wide" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlClose) && htmlClose, _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ImageTotalEditPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-edit-img", className: "popup-layer js-layer hidden popup-type1 w630" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.현장사진추가") }), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-edit-img") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ReportPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-report", className: "popup-layer js-layer hidden popup-type3" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uC120\uD0DD\uB41C Reports \uBAA9\uB85D" })), _jsx("button", Object.assign({ className: "btn btn-close js-close", onClick: (e) => jscloses_Popup("pop-report") }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function TitleEditPop(props) {
    const t = useTrans();
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-tit-edit", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: t("라벨.수정") }), (htmlClose) && htmlClose] })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function CompanyAddPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-add-company", className: "popup-layer js-layer hidden popup-type1 w480" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
function DeviceAllPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-finish-ok", className: "popup-layer js-layer hidden popup-type1 w1496 h97vh" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function WhatWeDoPop(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-whatwedo", className: "popup-layer js-layer hidden popup-type1 w1130" }, { children: [_jsx("div", Object.assign({ className: "popup__head" }, { children: (htmlClose) && htmlClose })), (CUTIL.isnull(popBody)) ?
                    _jsx(_Fragment, {})
                    : popBody] })) }));
}
;
function GuideRank(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function UpdateNickName(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function UpdateIntroduce(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function UpdateProfileImg(props) {
    //  body
    const popBody = props.popBody;
    // 닫기 버튼
    const htmlClose = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlClose")
                ? popBody.props.htmlClose
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: (CUTIL.isnull(popBody)) ?
            _jsx(_Fragment, {})
            : popBody }));
}
;
function jscloses_Popup(id) {
    CUTIL.jsclose_Popup(id);
}
