import { useTranslation } from 'react-i18next';
export function useTrans() {
    const { t } = useTranslation();
    // 다국어 처리할 메시지, 공통으로 들어갈 메세지
    return function $(key, data) {
        return t(key, Object.assign({}, data));
    };
}
export function useText() {
    const { t } = useTranslation();
    // 다국어 처리할 메시지, 공통으로 들어갈 메세지
    return function $(val, key, data) {
        let value = "";
        // data값 있는 경우
        if (data) {
            if (key) {
                value = `${key}.${val.replace(/[ .]/g, '')}`;
            }
            else {
                value = `라벨.${val.replace(/[ .]/g, '')}`;
            }
            return t(value, Object.assign({}, data));
        }
        else {
            if (key) {
                value = `${key}.${val.replace(/[ .]/g, '')}`;
            }
            else {
                value = `라벨.${val.replace(/[ .]/g, '')}`;
            }
            return t(value);
        }
    };
}
