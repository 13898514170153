export function genGUUID() {
    let s0 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s1 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s2 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s3 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s5 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s6 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s7 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let guid = s0 + s1 + "-" + s2 + "-" + s3 + "-" + s4 + "-" + s5 + "-" + s6 + s7;
    return guid;
}
let uuid = (sessionStorage.getItem("uuid") == null) ? null : sessionStorage.getItem("uuid");
export function genUUID() {
    let s0 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s1 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s2 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s3 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s5 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s6 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    let s7 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    if (uuid == null) {
        uuid = s0 + s1 + "-" + s2 + "-" + s3 + "-" + s4 + "-" + s5 + "-" + s6 + s7;
        sessionStorage.setItem("uuid", uuid);
        return uuid;
    }
    else {
        uuid = sessionStorage.getItem("uuid");
        return uuid;
    }
}
