import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTrans } from "../../../utils/langs/useTrans";
export default function MypageTree(props) {
    const t = useTrans();
    let isMine = props.isMine;
    const [isMobShow, setMobShow] = useState(false);
    const [selectMenuNm, setSelectMenuNm] = useState("알림");
    const [serchParams, setSearchParams] = useSearchParams();
    const type = serchParams.get('type') == null ? "" : serchParams.get('type');
    const navigate = useNavigate();
    useEffect(() => {
        if (!isMine)
            setSelectMenuNm("게시글");
    }, [isMine]);
    useEffect(() => {
        if (type != "") {
            let lis = document.querySelector(".lnb").children;
            Array.prototype.forEach.call(lis, (element) => {
                if (element.dataset.part == type) {
                    element.classList.add("on");
                }
                else {
                    element.classList.remove("on");
                }
            });
        }
    }, [type]);
    function movePart(e) {
        let lis = document.querySelector(".lnb").children;
        Array.prototype.forEach.call(lis, (element) => element.classList.remove("on"));
        props.setWork(e.currentTarget.dataset.part);
        e.currentTarget.classList.add("on");
        // navigate(CONST.URL_MYPAGE);
        setMobShow(false);
        setSelectMenuNm(e.currentTarget.childNodes[0].childNodes[0].data);
    }
    function onClickMobMenu() {
        setMobShow(!isMobShow);
    }
    return (_jsxs("div", Object.assign({ className: "left" }, { children: [_jsx("div", Object.assign({ className: "lnb__toggleBtn", onClick: onClickMobMenu }, { children: _jsx("p", { children: selectMenuNm }) })), _jsxs("ul", Object.assign({ className: `lnb ${isMobShow ? 'active' : ''}` }, { children: [(isMine) ? _jsx("li", Object.assign({ className: "on", "data-part": "NOTICE", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: t("라벨.알림") })) })) : "", _jsx("li", Object.assign({ className: `${!isMine ? 'on' : ''}`, "data-part": "MY_BOARD", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: `${(!isMine) ? t("커뮤니티.게시글") : t("커뮤니티.내게시글")}` })) })), _jsx("li", Object.assign({ "data-part": "MY_REPLY", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: `${(!isMine) ? t("커뮤니티.댓글") : t("커뮤니티.내댓글")}` })) })), (isMine) ? _jsx("li", Object.assign({ "data-part": "TEMP_BOARD", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: t("커뮤니티.임시저장글") })) })) : "", (isMine) ? _jsx("li", Object.assign({ "data-part": "LIKE_LIST", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: t("커뮤니티.좋아요목록") })) })) : "", (isMine) ? _jsx("li", Object.assign({ "data-part": "MARKET_LIKE", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: t("커뮤니티.마켓플레이스Like목록") })) })) : "", (isMine) ? _jsx("li", Object.assign({ "data-part": "MY_INFO", onClick: movePart }, { children: _jsx("a", Object.assign({ style: { cursor: "pointer" } }, { children: t("커뮤니티.내정보") })) })) : ""] }))] })));
}
