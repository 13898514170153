var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Bridge from "@utils/langs/BridgeApi";
import * as i18n from "@utils/langs/i18nUtils";
import React, { useEffect } from "react";
import * as ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import { oc } from "ts-optchain";
import EHPortal from "./ehp";
const initializeApp = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const { result } = yield Bridge.getNativeInfo();
        const nativeLang = oc(result).language();
        yield i18n.init(nativeLang);
        renderApp();
    }
    catch (error) {
        console.error("Error during i18n init:", error);
    }
});
const renderApp = () => {
    ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(RecoilRoot, { children: _jsx(EHPortal, {}) }) }), document.getElementById("app"));
};
const AppInitializer = () => {
    useEffect(() => {
        initializeApp();
    }, []);
    return null;
};
document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.render(_jsx(AppInitializer, {}), document.getElementById("app"));
});
