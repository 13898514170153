export function saveToFile_Chrome(fileName, content) {
    var blob = new Blob([content], { type: 'multipart/form-data' });
    var objURL = window.URL.createObjectURL(blob);
    var a = document.createElement('a');
    a.download = fileName;
    /*  clog("IN FILE  UTILS : " +  " : " + __dirname);
     clog("IN FILE  UTILS : " +  " : " + __filename);
     clog("IN FILE  UTILS : " +  " : " + a.download); */
    a.href = objURL;
    a.style.display = "none";
    // for new window
    a.target = "_blank";
    a.rel = 'noreferrer';
    //
    document.body.appendChild(a); //
    a.click();
    a.remove(); //
    window.URL.revokeObjectURL(objURL);
}
