var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import * as CONST from "../../../utils/Const";
import * as CUTIL from "../../../utils/commUtils";
import * as HTTPUTIL from "../../../utils/api/HttpUtil";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { communityPageState, myPageBoardSearchState, selectedCommunityId, userInfoLoginState } from "../../../recoil/userState";
import { alertState } from "../../../recoil/assessmentState";
import { loadingBoxState } from "../../../recoil/menuState";
import { useNavigate } from "react-router-dom";
import { useTrans } from "../../../utils/langs/useTrans";
import { Trans } from "react-i18next";
import { langId } from "../../../recoil/langState";
export default function MypageLike(props) {
    const navigate = useNavigate();
    const t = useTrans();
    //recoil
    const userInfo = useRecoilValue(userInfoLoginState);
    const [alert, setAlert] = useRecoilState(alertState);
    const loadingBox = useSetRecoilState(loadingBoxState);
    const [communityId, setCommunityId] = useRecoilState(selectedCommunityId);
    const langIds = useRecoilValue(langId);
    let [pageInfo, setPageInfo] = useState({
        totalElements: 0,
        totalPages: 0,
        number: 0,
        size: 10
    });
    const [searchParam, setSearchParam] = useRecoilState(myPageBoardSearchState);
    const [likeList, setLikeList] = useState([{
            community: {
                communityId: 0,
                category: {
                    id: 0,
                    code: "",
                    codeName: "",
                    role: "",
                    colorSet: "",
                    groupCode: "",
                    groupCodeName: ""
                },
                title: "",
                userNickName: "",
                updatedTime: ""
            },
            reply: {
                replyId: 0,
                content: "",
                userNickName: "",
                updatedTime: ""
            },
            statusCreatedTime: ""
        }]);
    const [isEmpty, setEmpty] = useState(false);
    useEffect(() => {
        setPageInfo({ number: 0, size: 10, totalElements: 0, totalPages: 0 });
        // setSearchParam({category:""});
    }, []);
    const obsRef = useRef(null);
    const preventRef = useRef(true);
    const endRef = useRef(false);
    useEffect(() => {
        setInterval(() => {
            const observer = new IntersectionObserver(obsHandler, { threshold: 0.5 });
            if (obsRef.current)
                observer.observe(obsRef.current);
            return () => { observer.disconnect(); };
        }, 2000);
    }, []);
    useEffect(() => {
        getList(null);
    }, []);
    function obsHandler(entries) {
        const target = entries[0];
        if (!endRef.current && target.isIntersecting && preventRef.current) {
            console.log("obs핸들러 실행");
            preventRef.current = false;
            getList("INFINITE_SCROLL");
        }
    }
    const [communityState, setCommunityPageState] = useRecoilState(communityPageState);
    function openWindow(item) {
        if (!CUTIL.isnull(item)) {
            // navigate(`/community?communityId=${id}`);
            // let url = window.location.origin + `/community?communityId=${item}`;
            // window.open(url, "_blank"); 
            setCommunityId(item);
            setCommunityPageState("LIST");
            navigate(CONST.URL_COMMUNITY + `?communityId=${item}&prvPage=LIKE_LIST`);
        }
    }
    function getList(option) {
        return __awaiter(this, void 0, void 0, function* () {
            let appPath = "";
            let searchPage = { totalElements: 0, totalPages: 0, number: 0, size: 10 };
            setPageInfo(prevState => { searchPage = prevState; return Object.assign({}, prevState); });
            if (option == "INFINITE_SCROLL") {
                appPath = `page=${searchPage.number + 1}&size=10`;
            }
            else {
                appPath = `page=0&size=10`;
            }
            let ListData = yield HTTPUTIL.PromiseHttp({
                httpMethod: "GET",
                appPath: `/api/v3/community/likelist/mine?${appPath}`,
                appQuery: {},
                userToken: userInfo.loginInfo.token,
            });
            const ERR_URL = HTTPUTIL.resultCheck(true, ListData);
            if (ERR_URL.length > 0)
                navigate(ERR_URL);
            if (ListData) {
                if (ListData.codeNum == CONST.API_200) {
                    let res = ListData.body;
                    if (CUTIL.isnull(res)) {
                        setEmpty(true);
                    }
                    else {
                        let page = ListData.data.page;
                        setPageInfo(page);
                        if (option == "INFINITE_SCROLL") {
                            console.log("Infinite Scroll");
                            preventRef.current = true;
                            setLikeList(list => [...list, ...res]);
                            if ((page.number + 1) * page.size >= page.totalElements) {
                                endRef.current = true;
                            }
                        }
                        else {
                            setLikeList(ListData.body);
                            if ((page.number + 1) * page.size >= page.totalElements) {
                                endRef.current = true;
                            }
                            else {
                                endRef.current = false;
                            }
                        }
                    }
                }
            }
        });
    }
    function createBottom(index) {
        if (likeList[0].noticeId != 0) {
            let likes = [...likeList];
            let item = likes[index];
            let content = [];
            if (item != null) {
                if (!CUTIL.isnull(item.community)) {
                    content.push(_jsxs("div", Object.assign({ className: "top" }, { children: [_jsx("p", Object.assign({ className: `cate ${CUTIL.getCategoryClass(item.community.category.colorSet)}` }, { children: item.community.category.codeName })), _jsx("p", { children: item.community.title })] })));
                }
                if (!CUTIL.isnull(item.reply)) {
                    content.push(_jsx("div", Object.assign({ className: "bottom" }, { children: _jsx("p", Object.assign({ className: `answer` }, { children: item.reply.content })) })));
                }
            }
            return content;
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "item-list-top" }, { children: _jsx("div", Object.assign({ className: "left" }, { children: _jsx("p", Object.assign({ className: "tit" }, { children: t("커뮤니티.좋아요목록") })) })) })), _jsxs("div", { children: [_jsx("ul", Object.assign({ className: "list-area" }, { children: (likeList.length != 0 && likeList[0].statusCreatedTime != "") && likeList.map((item, index) => (_jsxs("li", { children: [_jsxs("div", Object.assign({ className: "list-top" }, { children: [_jsx("div", Object.assign({ className: "left" }, { children: _jsx(Trans, Object.assign({ i18nKey: (item.reply == null) ? "커뮤니티.LIKE.게시글" : "커뮤니티.LIKE.댓글", values: { user: (item.reply == null) ? item.community.userNickName : item.reply.userNickName } }, { children: _jsx("p", { children: `회원님이 ${(item.reply == null) ? item.community.userNickName : item.reply.userNickName}님의 ${(item.reply == null) ? '게시글' : '댓글'}을 좋아합니다.` }) })) })), _jsx("div", Object.assign({ className: "right" }, { children: _jsx("p", { children: CUTIL.setTodayDate(item.statusCreatedTime, langIds) }) }))] })), _jsx("div", Object.assign({ className: "list-bottom" }, { children: _jsx("a", Object.assign({ onClick: (e) => openWindow(item.community.communityId) }, { children: createBottom(index) })) }))] }))) })), (isEmpty ?
                        _jsx("div", Object.assign({ className: "nodata" }, { children: _jsx("p", Object.assign({ className: "nodata__txt" }, { children: t("커뮤니티.NODATA") })) })) : "")] }), _jsx("div", { style: { position: "absolute", top: "95%" }, ref: obsRef })] }));
}
