import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /help
 * @CONST  CONST.URL_HELP
 * @menu 서비스 소개
 * @mapping 도움말 화면
 */
import React, { useState, useEffect } from "react";
// recoil
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { urlState, menuListState, parentCode, contactusState } from '../../recoil/menuState';
// css
import "/static/css/swiper.css";
import * as CUTIL from "../../utils/commUtils";
// component
import Header from "../header/Header";
import EhpImageCrop from "../common/imagecrop/EhpImageCrop";
import IntroMain from "../intro/IntroMain";
import { curSchduleTreeState, curMonitoringTreeState, curAdminTreeState, checkTypeState, mainState, noneUrl } from "../../recoil/assessmentState";
/**
 * @param props0 curTabMenu :  menu api의 data의 url 화면
 */
function IntroLayout(props) {
    // recoil
    const [urlInfo, setRecoilUrlInfo] = useRecoilState(urlState);
    const [menuRecoilList, setRecoilMenuList] = useRecoilState(menuListState);
    const setRecoilTreeData = useSetRecoilState(curSchduleTreeState);
    const setRecoilData = useSetRecoilState(curMonitoringTreeState);
    const setRecoilAdminData = useSetRecoilState(curAdminTreeState);
    const setCheck = useSetRecoilState(checkTypeState);
    const setMenuCodeRecoil = useSetRecoilState(parentCode);
    const setRecoilState = useSetRecoilState(mainState);
    const setReContactusState = useSetRecoilState(contactusState);
    const recoilNoneUrlInfo = useRecoilValue(noneUrl);
    const [menuList, setMenuList] = useState(null);
    // props
    const tabId = props.hasOwnProperty("tabId") ? props.tabId : 0;
    // tab init
    // clog("IN LAYOUT : 서비스소개 : INIT : " + urlInfo);
    const [tabMenuList, setTabMenuList] = useState(null);
    const [curTabMenu, setCurTabMenu] = useState(null);
    // 특정 화면에서 url로 menu 정보 추출하기 위함
    useEffect(() => {
        let tmenuList = menuRecoilList;
        if (tmenuList.length <= 0) {
        }
        let curMenu = null;
        tmenuList.filter((smenu) => smenu.url === urlInfo).map((smenu) => {
            if (smenu.url === urlInfo)
                curMenu = smenu;
        });
        setMenuCodeRecoil((curMenu) && curMenu.parentCode);
        const tempMenu = [];
        tmenuList.filter((smenu) => (curMenu) && (smenu.parentCode === curMenu.parentCode)).map((smenu) => {
            tempMenu.push({
                "menuName": smenu.menuName,
                "menuUrl": smenu.url,
            });
        });
        setMenuList(tmenuList);
        if (!recoilNoneUrlInfo) {
            //초기화
            setRecoilData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: false },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilTreeData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "", zoneBoolean: true },
                subZone: { subZoneId: "", subZoneName: "", subZoneBoolean: true },
                room: { roomId: "", roomName: "", roomBoolean: false },
                spg: { companySpgId: -1, spgId: -1, spgName: "", spgBoolean: false }
            });
            setRecoilAdminData({
                company: { companyId: "", companyName: "" },
                zone: { zoneId: "", zoneName: "" },
                subZone: { subZoneId: "", subZoneName: "", },
                room: { roomId: "", roomName: "", },
            });
            setCheck({
                checkType: "daily",
                checkOn: false,
                nextStep: "",
                preStep: "",
                assessmentId: -1,
                checkitemId: -1,
                preCheckitemId: -1,
                checkvalueId: -1,
                itemNum: -1,
                totalComment: "",
                itemInfo: {
                    id: -1,
                    itemId: "",
                    itemName: "",
                    panelName: "",
                    serialNo: "",
                    spgId: -1
                },
                chekTypeInfo: {
                    checkTypeId: -1,
                    checkTypeName: "",
                },
                chekSubTypeInfo: {
                    checkSubTypeId: -1,
                    checkSubName: "",
                },
                chekVersionTypeInfo: {
                    checkVersionId: -1,
                    versionNo: "",
                },
                assessmentInfo: {
                    isTempSave: true,
                    reportId: -1,
                },
                checkvalue: {
                    action: "",
                    comment: "",
                    input: -1,
                    isChecked: false,
                    value: -1,
                },
                listType: -1,
                processRate: -1,
                startPage: -1,
                endpage: -1,
                dueDate: "",
                instrument: -1,
                countOpenClose: -1,
                treeName: "",
                schdeulerId: -1,
                disabled: false,
                period: -1,
                checkRef: [],
                isReportUpdated: false,
                inspector: "",
            });
            setRecoilState({
                companyId: "",
                zoneId: "",
            });
            setReContactusState(null);
        }
    }, [urlInfo, menuRecoilList]);
    //
    const [popUserApprrval, setPopUserApprrval] = useState(_jsx(UserApprrvalPop, { popBody: null }));
    const [popSiteCeatePop, setPopSiteCeatePop] = useState(_jsx(SiteCeatePop, { popBody: null }));
    const [popSmall, setPopSmall] = useState(_jsx(SmallPop, { popBody: null }));
    const [popUserRole, setPopUserRole] = useState(_jsx(UserRolePop, { popBody: null }));
    const [popSearch, setPopSearch] = useState(_jsx(SearchPopup, { popBody: null }));
    const [popFilter, setPopFilter] = useState(_jsx(FilterPopup, { popBody: null }));
    const [popChange, setPopChange] = useState(_jsx(ChangePopup, { popBody: null }));
    const [popListView, setPopListView] = useState(_jsx(ListViewPopup, { popBody: null }));
    // 성적서 pop
    const [popTestView, setPopTestView] = useState(_jsx(PopupReportView, { popBody: null }));
    //ehc-workorder
    const [popEhcDetailView, setPopEhcDetailView] = useState(_jsx(EhcDetailViewPopup, { popBody: null }));
    // 공통 - crop
    const [popImgCrop, setPopImgCrop] = useState(_jsx(EhpImageCrop, { popBody: null }));
    // 공통 - postcode
    useEffect(() => {
    }, [tabId]);
    useEffect(() => {
        setPopUserApprrval(popUserApprrval);
        setPopSiteCeatePop(popSiteCeatePop);
        setPopSmall(popSmall);
        setPopUserRole(popUserRole);
        setPopSearch(popSearch);
        setPopFilter(popFilter);
        setPopChange(popChange);
        setPopListView(popListView);
        //
        setPopTestView(popTestView);
        // ehc-workorder
        setPopEhcDetailView(popEhcDetailView);
        // 공통
        setPopImgCrop(popImgCrop);
    }, [popUserApprrval, popSiteCeatePop,
        popSmall, popUserRole, popSearch, popListView,
        popEhcDetailView, popFilter, popTestView,
        popChange,
        popImgCrop, // 공통
    ]);
    function scrollToTop(e) {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap" }, { children: [_jsx(Header, {}), (urlInfo) && _jsx(IntroMain, { urlInfo: urlInfo }), popUserApprrval, popSiteCeatePop, popSmall, popUserRole, popSearch, popFilter, popChange, popListView, popTestView, popEhcDetailView, popImgCrop] })) }));
}
export default IntroLayout;
// 닫기버튼 추가 팝업
function UserApprrvalPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-userjoin-ok", className: "popup-layer js-layer layer-out hidden page-detail userjoin-ok" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, (htmlHeaderBtn) && htmlHeaderBtn] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
// 기본 팝업
function SiteCeatePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-userjoin-info", className: "popup-layer js-layer hidden page-detail userjoin-ok" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
// 작은 팝업
function SmallPop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ id: "pop-small", className: "popup-layer js-layer hidden layer-out popup-basic" }, { children: _jsxs("div", Object.assign({ className: "page-detail" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, (htmlHeaderBtn) && htmlHeaderBtn] })), (CUTIL.isnull(popBody))
                        ? _jsx(_Fragment, {})
                        : popBody] })) })) }));
}
// 아주 작은 팝업
function UserRolePop(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-role", className: "popup-layer popup-basic hidden page-detail w400" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, (htmlHeaderBtn) && htmlHeaderBtn] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
// 검색 팝업
function SearchPopup(props) {
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-search-small", className: "popup-layer layer-out js-layer hidden page-detail page-report page-list-in" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-search" }, { children: "\uAC80\uC0C9" })), _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (popBody === null)
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
// 모바일 상세 팝업
function ListViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-list-view", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
// check message 조회 팝업
function EhcDetailViewPopup(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-list-ehcdetailview", className: "popup-layer js-layer layer-out hidden page-report page-detail page-workplace page-info page-list-view" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (CUTIL.isnull(popBody))
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function FilterPopup(props) {
    const popBody = props.popBody;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-filter", className: "popup-layer js-layer layer-out hidden page-detail page-workplace page-report" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", Object.assign({ className: "icon-filter" }, { children: "Filter" })), _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (popBody === null)
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
function ChangePopup(props) {
    const popBody = props.popBody;
    const htmlHeaderBtn = (!CUTIL.isnull(popBody))
        ? popBody.props.hasOwnProperty("htmlHeaderBtn")
            ? popBody.props.htmlHeaderBtn
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ id: "pop-change", className: "popup-layer js-layer hidden layer-out popup-basic" }, { children: _jsxs("div", Object.assign({ className: "page-detail" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [_jsx("h1", { children: "\uB178\uD6C4\uAD50\uCCB4 \uC694\uCCAD" }), (htmlHeaderBtn) && htmlHeaderBtn] })), (popBody === null)
                        ? _jsx(_Fragment, {})
                        : popBody] })) })) }));
}
function PopupReportView(props) {
    const popBody = props.popBody;
    const htmlHeader = (!CUTIL.isnull(popBody))
        ? popBody.hasOwnProperty("props")
            ? popBody.props.hasOwnProperty("htmlHeader")
                ? popBody.props.htmlHeader
                : null
            : null
        : null;
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "pop-downtest", className: "popup-layer js-layer layer-out hidden w720" }, { children: [_jsxs("div", Object.assign({ className: "popup__head" }, { children: [(htmlHeader) && htmlHeader, _jsx("button", Object.assign({ className: "btn btn-close js-close" }, { children: _jsx("span", Object.assign({ className: "hide" }, { children: "\uB2EB\uAE30" })) }))] })), (popBody === null)
                    ? _jsx(_Fragment, {})
                    : popBody] })) }));
}
