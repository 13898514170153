var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import cn from '@utils/langs/locales/cn_CH.json';
import en from '@utils/langs/locales/en_US.json';
import ko from '@utils/langs/locales/ko_KR.json';
const LANGUAGE = {
    KO: 'ko_KR',
    EN: 'en_US',
    CH: 'cn_CH',
};
export let $ = () => { };
export function init(currentLanguage) {
    return __awaiter(this, void 0, void 0, function* () {
        return i18n
            .use(initReactI18next)
            .init({
            debug: process.env.NODE_ENV.includes('dev'),
            load: 'currentOnly',
            interpolation: {
                escapeValue: true,
                prefix: '{',
                suffix: '}',
            },
            lng: currentLanguage,
            fallbackLng: LANGUAGE.KO,
            resources: {
                en_US: en,
                ko_KR: ko,
                cn_CH: cn,
            },
        })
            .then(t => {
            $ = t;
        });
    });
}
export function changeLang(currentLanguage) {
    return __awaiter(this, void 0, void 0, function* () {
        localStorageLang(currentLanguage);
        return i18n
            .use(initReactI18next)
            .changeLanguage(currentLanguage);
    });
}
export function localStorageLang(currentLanguage) {
    localStorage.setItem("langs", currentLanguage);
}
