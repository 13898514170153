import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { userInfoLoginState } from "../../../recoil/userState";
import { alertState, confirmState } from "../../../recoil/assessmentState";
import { loadingBoxState, marketWork } from "../../../recoil/menuState";
import { useAsync } from "react-async";
import * as CONST from "../../../utils/Const";
import * as CUTIL from "../../../utils/commUtils";
import * as HTTPUTIL from "../../../utils/api/HttpUtil";
import { useNavigate } from "react-router-dom";
import { useTrans } from "../../../utils/langs/useTrans";
import { langState } from "../../../recoil/langState";
export default function MypageMarketLike(props) {
    const navigate = useNavigate();
    const t = useTrans();
    //recoil
    const userInfo = useRecoilValue(userInfoLoginState);
    const [alert, setAlert] = useRecoilState(alertState);
    const loadingBox = useSetRecoilState(loadingBoxState);
    const [confirm, setConfirm] = useRecoilState(confirmState);
    const [langs, setRecoilLangs] = useRecoilState(langState); // recoil langState
    const setRecoilMarkerWorks = useSetRecoilState(marketWork);
    //
    const { handleOpnePop } = props;
    const [marketList, setMarketList] = useState([]);
    var pageInfo = { totalElements: 0, totalPages: 0, number: 0, size: 6 };
    const [isInfinity, setIsInfinity] = useState(false);
    const [isEmpty, setEmpty] = useState(false);
    const obsRef = useRef(null);
    const preventRef = useRef(true);
    const endRef = useRef(false);
    useEffect(() => {
        setIsInfinity(false);
        loadingBox(true);
        setInterval(() => {
            const observer = new IntersectionObserver(obsHandler, { threshold: 0.5 });
            if (obsRef.current)
                observer.observe(obsRef.current);
            return () => { observer.disconnect(); };
        }, 1000);
    }, []);
    function obsHandler(entries) {
        const target = entries[0];
        if (!endRef.current && target.isIntersecting && preventRef.current) {
            console.log("obs핸들러 실행");
            setIsInfinity(true);
            preventRef.current = false;
            pageInfo.number = pageInfo.number + 1;
            setPage(`&page=${pageInfo.number}&size=${pageInfo.size}`);
        }
    }
    const [page, setPage] = useState(`&page=${pageInfo.number}&size=${pageInfo.size}`);
    const { data: data, isLoading } = useAsync({
        promiseFn: HTTPUTIL.PromiseHttp,
        httpMethod: "GET",
        appPath: `/api/v3/energyconsulting/solution/search?searchFilter=isSelected${page}`,
        appQuery: {},
        userToken: userInfo.loginInfo.token,
        watch: page
    });
    useEffect(() => {
        loadingBox(false);
        const ERR_URL = HTTPUTIL.resultCheck(isLoading, data);
        if (ERR_URL.length > 0)
            navigate(ERR_URL);
        if (data) {
            if (data.codeNum == CONST.API_200) {
                let body = data.body;
                if (CUTIL.isnull(body)) {
                    setEmpty(true);
                }
                else {
                    let page = data.data.page;
                    preventRef.current = true;
                    // setPageInfo(page);
                    pageInfo = page;
                    if (isInfinity) {
                        setMarketList(list => [...list, ...body]);
                        if ((page.number + 1) * page.size >= page.totalElements) {
                            endRef.current = true;
                        }
                    }
                    else {
                        setMarketList(body);
                        if ((page.number + 1) * page.size >= page.totalElements) {
                            endRef.current = true;
                        }
                    }
                }
            }
        }
    }, [data]);
    function goDetail(id) {
        setRecoilMarkerWorks("VIEW");
        // navigate(CONST.URL_MARKETPLACE);
        let url = CONST.URL_MARKETPLACE + `?solutionId=${id}&prvPage=MARKET_LIKE`;
        navigate(url);
        // window.open(url, "_blank"); 
    }
    function geContact() {
        let url = `/contactus?prvPage=MARKET_LIKE`;
        navigate(url);
        // window.open(url, "_blank");
    }
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "item-list-top" }, { children: _jsx("div", Object.assign({ className: "left" }, { children: _jsx("p", Object.assign({ className: "tit" }, { children: t("커뮤니티.마켓플레이스Like목록") })) })) })), _jsx("div", Object.assign({ className: "item-list-area" }, { children: _jsxs("div", Object.assign({ className: "m-m10" }, { children: [(marketList.length > 0 && marketList[0].solutionId != 0) &&
                            _jsx("ul", Object.assign({ className: "item-list item-like p-10" }, { children: marketList.map((item, index) => (_jsxs("li", { children: [_jsxs("div", Object.assign({ className: "img-wrap" }, { children: [(item.imageUrl == null) ?
                                                    _jsxs("p", Object.assign({ className: "tit" }, { children: [_jsx("strong", { children: item.solutionName }), _jsx("span", { children: item.solutionSubTitle })] })) : "", (item.imageUrl != null) ?
                                                    _jsx("img", { src: item.imageUrl }) : _jsx("img", { src: require("/static/img/default.png") })] })), _jsxs("div", Object.assign({ className: "item-info" }, { children: [_jsxs("div", Object.assign({ className: `item-info-top ${item.lsBest ? 'best' : ''}`, style: { overflow: "scroll" } }, { children: [_jsx("div", Object.assign({ className: "flex-row left-right" }, { children: _jsx("p", Object.assign({ className: "keyword" }, { children: (item.solutionTag) && item.solutionTag.map((tags, tIndex) => (_jsx("span", { children: tags }, `tag_${index}_${tIndex}`))) })) })), _jsx("p", Object.assign({ className: "tit" }, { children: item.solutionName })), _jsx("p", Object.assign({ className: "company" }, { children: item.companyName })), _jsx("p", Object.assign({ className: "cont" }, { children: item.description })), _jsx("ul", Object.assign({ className: "etc" }, { children: _jsx("li", { children: item.extraInfo }) }))] })), _jsxs("div", Object.assign({ className: "item-info-btnarea" }, { children: [_jsx("button", Object.assign({ onClick: geContact }, { children: _jsx("span", Object.assign({ className: "icon-go" }, { children: t("라벨.상담신청하기") })) })), _jsx("button", Object.assign({ onClick: (e) => goDetail(item.solutionId) }, { children: _jsx("span", Object.assign({ className: "icon-more" }, { children: t("라벨.상세보기") })) }))] }))] }))] }, `market_${index}`))) })), (isEmpty == true ?
                            _jsx("div", Object.assign({ className: "nodata" }, { children: _jsx("p", Object.assign({ className: "nodata__txt" }, { children: t("커뮤니티.NODATA") })) })) : "")] })) })), _jsx("div", { style: { position: "absolute", top: "95%" }, ref: obsRef })] }));
}
