import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/**
 * @url /systemerror
 * @CONST CONST.URL_SYSTEM_ERROR
 * @menu 없음
 * @mapping 서비스 점검 화면
 */
import React from "react";
// css
import "/static/css/style.css";
import "/static/css/content.css";
// component
import Header from "../header/Header";
import SystemError from "../common/systemerror/SystemError";
/**
 * @param props0 -
 */
function SystemErrorLayout() {
    return (_jsx(_Fragment, { children: _jsxs("div", Object.assign({ id: "wrap" }, { children: [_jsx(Header, {}), _jsx(SystemError, {})] })) }));
}
export default SystemErrorLayout;
